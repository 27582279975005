




























import Vue, { PropType } from 'vue'
import { IMonitorStateLoopData } from '@/interfaces/monitor-state'
import SimpleMetricField from '@/ui/components/metrics/SimpleMetricField.vue'
import moment from 'moment'
import { IProduct } from '@/interfaces/strategy'

export default Vue.extend({
  name: 'OpeningMonitorState',
  components: { SimpleMetricField },
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true
    },
    state: {
      type: Object as PropType<IMonitorStateLoopData>,
      required: true
    },
    isRunning: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {}
  },
  computed: {
    duration (): number {
      if (!this.state.opening) {
        return 0
      }

      return (this.state.opening.endTimestamp || Date.now()) - this.state.opening.startTimestamp
    },
    startDate (): string {
      if (!this.state.closing) {
        return ''
      }

      return moment(this.state.closing.startTimestamp).toISOString()
    },
    sideColor (): string {
      if (!this.state.opening || !this.state.opening.side) {
        return '#b9b9c3'
      }

      return this.state.opening.side > 0 ? '#28c76f' : '#ea5455'
    },
    sideIcon (): string {
      if (!this.state.opening || !this.state.opening.side) {
        return 'question'
      }

      return this.state.opening.side > 0 ? 'long-arrow-alt-up' : 'long-arrow-alt-down'
    }
  },
  methods: {
    onDetailsClick (): void {
      this.$emit('details-click', this.state.opening)
    }
  }
})
