













































import Vue from 'vue'
import { groupBy, orderBy } from 'lodash'
import { IServerItem } from '@/store/servers/types'
import store from '@/store'
import {
  IAccountEntity,
  IMonitorStateClosingEntity,
  IMonitorStateEntity,
  IMonitorStateOpeningEntity,
  IMonitorStateRunningEntity,
  MonitorStateType
} from '@/core/http/remote-interfaces/persistence-remote'
import { TradingMonitoringService } from '@/core/services/trading/trading-monitoring-service'
import { IStrategyData } from '@/interfaces/strategy'
import StrategyInfo from '@/ui/components/strategies/StrategyInfo.vue'
import SimpleMetricCard from '@/ui/components/metrics/SimpleMetricCard.vue'
import StrategyStates from '@/ui/components/strategies/StrategyStates.vue'
import { IMonitorStateLoopData } from '@/interfaces/monitor-state'
import { IStrategyState } from '@/core/http/remote-interfaces/trading-remote'

export default Vue.extend({
  name: 'ServerStrategyDetails',
  components: { StrategyStates, SimpleMetricCard, StrategyInfo },
  props: {
    serverId: {
      type: String,
      required: true
    },
    strategyId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      strategy: null as IStrategyData | null,
      monitorStates: [] as Array<IMonitorStateLoopData>,
      currentState: undefined as IStrategyState | undefined
    }
  },
  computed: {
    serverItem (): IServerItem | undefined {
      return store.state.servers.list.find(
        (s) => s.server._id === this.serverId
      )
    },
    account (): IAccountEntity | undefined {
      if (!this.serverItem || !this.strategy) {
        return
      }

      return this.serverItem.accounts.find(
        (a) => a._id === (this.strategy as IStrategyData).entity.accountID
      )
    }
  },
  async mounted () {
    await this.fetchStrategy()
    await this.fetchStrategyStates()

    setInterval(() => {
      this.updateStrategyStates()
    }, 2500)
  },
  filters: {
    formatUSD: (value: number): string => {
      return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value / 100) / 100)}`
    }
  },
  methods: {
    async fetchStrategy () {
      if (!this.serverItem || !this.strategyId) {
        return
      }

      try {
        const strategyEntity = await TradingMonitoringService.getEntity(
          this.serverItem.server.url,
          this.strategyId
        )
        const strategyState = await TradingMonitoringService.getState(
          this.serverItem.server.url,
          this.strategyId
        )

        this.strategy = {
          entity: strategyEntity,
          state: strategyState,
          product: this.serverItem.products[strategyEntity.productSymbol]
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchStrategyStates () {
      if (!this.serverItem || !this.strategyId) {
        return
      }

      try {
        const monitorStates =
          await TradingMonitoringService.getStrategyMonitorStates(
            this.serverItem.server.url,
            this.strategyId
          )
        this.currentState = await TradingMonitoringService.getState(
          this.serverItem.server.url,
          this.strategyId
        )
        if (this.currentState && this.currentState.stateMonitor) {
          monitorStates.push(this.currentState.stateMonitor as any)
        }

        const monitorStatesMap = groupBy(monitorStates, 'loopID')
        const transformedMonitorStates: Array<IMonitorStateLoopData> = []

        let i = 0
        for (const loopID in monitorStatesMap) {
          const monitorStateLoop: IMonitorStateLoopData = {
            id: loopID,
            opening: monitorStatesMap[loopID].find(
              (m) => m.stateType === 'MonitorStateOpening'
            ) as IMonitorStateOpeningEntity,
            running: monitorStatesMap[loopID].find(
              (m) => m.stateType === 'MonitorStateRunning'
            ) as IMonitorStateRunningEntity,
            closing: monitorStatesMap[loopID].find(
              (m) => m.stateType === 'MonitorStateClosing'
            ) as IMonitorStateClosingEntity
          }
          i++

          transformedMonitorStates.push(monitorStateLoop)
        }

        this.monitorStates = orderBy(transformedMonitorStates, 'id', 'desc')
      } catch (error) {
        console.log(error)
      }
    },
    async updateStrategyStates () {
      if (!this.serverItem || !this.strategyId) {
        return
      }

      if (this.monitorStates.length === 0) {
        // strategy has monitor states
        await this.fetchStrategyStates()
        return
      }

      let lastLoop = this.monitorStates[0]
      const newStates =
        await TradingMonitoringService.getStrategyMonitorStatesFromLoop(
          this.serverItem.server.url,
          this.strategyId,
          lastLoop.id
        )
      this.currentState = await TradingMonitoringService.getState(
        this.serverItem.server.url,
        this.strategyId
      )

      if (
        String(this.currentState.loopID) === lastLoop.id &&
        this.currentState.stateMonitor
      ) {
        this.setCurrentState(this.currentState.stateMonitor, lastLoop)
      } else if (
        String(this.currentState.loopID) !== lastLoop.id &&
        this.currentState.stateMonitor
      ) {
        newStates.push(this.currentState.stateMonitor as any)

        for (const newState of newStates) {
          if (String(newState.loopID) !== lastLoop.id) {
            this.monitorStates.unshift({
              id: String(newState.loopID),
              opening: undefined,
              running: undefined,
              closing: undefined
            })

            lastLoop = this.monitorStates[0]
          }

          this.setCurrentState(newState, lastLoop)
        }
      }
    },
    setCurrentState (
      state: IMonitorStateEntity,
      stateLoopData: IMonitorStateLoopData
    ) {
      switch (state.stateType) {
        case MonitorStateType.OPENING:
          stateLoopData.opening = state as IMonitorStateOpeningEntity
          break
        case MonitorStateType.RUNNING:
          stateLoopData.running = state as IMonitorStateRunningEntity
          break
        case MonitorStateType.CLOSING:
          stateLoopData.closing = state as IMonitorStateClosingEntity
          break
      }
    }
  }
})
