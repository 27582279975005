




















import Vue, { PropType } from 'vue'

interface IOptions {
  itemsPerPage: number
}

export default Vue.extend({
  name: 'StrategiesTableOptions',
  props: {
    options: {
      type: Object as PropType<IOptions>,
      required: false,
      default: () => ({
        itemsPerPage: 50
      })
    }
  }
})
