













































import Vue from "vue";
import {
  HealthcheckResult,
  HealthcheckStatusEnum,
} from "@/core/http/remote-interfaces/healthcheck-remote";
import { IhmHealthService } from "@/core/services/ihm/ihm-health-service";

export default Vue.extend({
  name: "Users",
  components: {},
  async created() {
    await this.update();
    this.interval = setInterval(() => {
      this.update();
    }, 5000);
  },
  async beforeDestroy() {
    clearInterval(this.interval);
  },
  data(): {
    health: HealthcheckResult;
    errorMessage: string;
    interval: any;
  } {
    return {
      health: {
        status: "loading" as HealthcheckStatusEnum,
        checks: {},
      },
      errorMessage: "",
      interval: {},
    };
  },
  methods: {
    update: async function () {
      try {
        this.errorMessage = "";
        this.health = await IhmHealthService.getHealth();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
});
