import { render, staticRenderFns } from "./SimpleMetricCard.vue?vue&type=template&id=6af8c4b2&scoped=true&"
import script from "./SimpleMetricCard.vue?vue&type=script&lang=ts&"
export * from "./SimpleMetricCard.vue?vue&type=script&lang=ts&"
import style0 from "./SimpleMetricCard.vue?vue&type=style&index=0&id=6af8c4b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af8c4b2",
  null
  
)

export default component.exports