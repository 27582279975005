import { IAccountEntity } from '@/core/http/remote-interfaces/persistence-remote'
import { TradingHttpClient } from '@/core/http/trading-http-client'

export class TradingAccountsService {
  public static async fetchAllAccounts(serverUrl: string): Promise<Array<IAccountEntity>> {
    const response = await TradingHttpClient.accounts(serverUrl)

    return response.data
  }

  public static async createAccount(serverUrl: string, request: Pick<IAccountEntity, "name" | "apiID" | "apiKey">): Promise<IAccountEntity> {
    const response = await TradingHttpClient.createAccount(serverUrl, request)

    return response.data
  }

  public static async deleteAccount(serverUrl: string, accountID: string): Promise<IAccountEntity> {
    const response = await TradingHttpClient.deleteAccount(serverUrl, accountID)

    return response.data
  }

  public static async verifyAccount(serverUrl: string, accountID: string): Promise<void> {
    const response = await TradingHttpClient.verifyAccount(serverUrl, accountID)
  }
}
