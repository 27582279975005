













import Vue, { PropType } from 'vue'
import { IMonitorStateEntity } from '@/core/http/remote-interfaces/persistence-remote'

export default Vue.extend({
  name: 'MonitorStateDetailsModal',
  props: {
    state: {
      type: Object as PropType<IMonitorStateEntity>,
      required: false
    }
  },
  computed: {
    jsonData(): string {
      const jsonString = JSON.parse(this.state.jsonString)

      return JSON.stringify({
        ...this.state,
        jsonString
      }, null, 4)
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
})
