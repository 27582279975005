

















































import Vue, { PropType } from 'vue'
import { IStrategyData } from '@/interfaces/strategy'
import { IServerItem } from '@/store/servers/types'
import { IAccountEntity } from '@/core/http/remote-interfaces/persistence-remote'
import SchemaObject from '@/ui/components/schema/schema-object.vue'
import { IStrategyConfigSchema } from '@/core/http/interfaces/trading-requests'

export default Vue.extend({
  name: 'StrategyInfo',
  components: { SchemaObject },
  props: {
    server: {
      type: Object as PropType<IServerItem>,
      required: true
    },
    strategy: {
      type: Object as PropType<IStrategyData>,
      required: true
    },
    account: {
      type: Object as PropType<IAccountEntity>,
      required: true
    }
  },
  data () {
    return {
      config: {
        value: this.strategy.entity.config,
        open: false,
        updating: false
      },
    }
  },
  computed: {
    product (): string {
      return this.strategy.entity.productSymbol.replace('USD', '')
    },
    currentConfigSchema (): IStrategyConfigSchema | undefined {
      const definition = this.server.definitions.find(d => d.name === this.strategy.entity.strategyName)

      return definition ? definition.configSchema : undefined
    }
  },
  methods: {
    async onConfigUpdate() {
      this.config.updating = true
    }
  }
})
