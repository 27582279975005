






















import Vue from 'vue'
import Decimal from 'decimal.js'

export default Vue.extend({
  name: 'NumberInput',
  props: {
    value: {
      type: [Number, String]
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: -Infinity
    },
    max: {
      type: Number,
      default: Infinity
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: 'is-light'
    },
    alignment: {
      type: String,
      default: 'right'
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      invalid: false
    }
  },
  computed: {
    inputClass (): Record<string, boolean> {
      return {
        input: true,
        'is-danger': this.invalid
      }
    }
  },
  methods: {
    onInput () {
      const $input = this.$refs.input as HTMLInputElement
      const value = parseFloat($input.value)

      this.invalid = (this.required && $input.value.length === 0)
        || isNaN(value)
        || !$input.checkValidity()
        || this.min > value
        || this.max < value

      this.$emit('input', $input.value)
    },
    onButtonClick (sign: number = 1) {
      if (this.invalid || this.disabled) {
        return
      }

      const stringValue = (this.$refs.input as HTMLInputElement).value
      const numberValue = isNaN(parseFloat(stringValue)) ? 0 : parseFloat(stringValue)

      let decimalValue = new Decimal(numberValue).add(sign * this.step)
      if (decimalValue.gt(this.max)) {
        decimalValue = new Decimal(this.max)
      } else if (decimalValue.lt(this.min)) {
        decimalValue = new Decimal(this.min)
      }

      this.$emit('input', decimalValue.valueOf())
    }
  }
})
