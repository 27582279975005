import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AppRoutes } from '@/core/constants'
import { AuthService } from '@/core/services/ihm/ihm-auth-service'
import SplashScreen from '@/ui/views/SplashScreen.vue'
import AuthRegister from '@/ui/views/auth/AuthRegister.vue'
import AuthLogin from '@/ui/views/auth/AuthLogin.vue'
import Dashboard from '@/ui/views/Dashboard.vue'
import Users from '@/ui/views/users/Users.vue'
import Health from '@/ui/views/health/Health.vue'
import ConfigAddServer from '@/ui/views/config/ConfigAddServer.vue'
import ServerAccounts from '@/ui/views/servers/ServerAccounts.vue'
import ServerMonitoring from '@/ui/views/servers/ServerMonitoring.vue'
import ServerExchangeWebsockets from '@/ui/views/servers/ServerExchangeWebsockets.vue'
import ServerKlines from '@/ui/views/servers/ServerKlines.vue'
import ServerStrategies from '@/ui/views/servers/ServerStrategies.vue'
import Debug from '@/ui/views/Debug.vue'
import ServerStrategyDetails from '@/ui/views/servers/ServerStrategyDetails.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: AppRoutes.splashScreen,
    name: 'SplashScreen',
    component: SplashScreen,
    meta: { layout: 'blank', auth: false }
  },
  {
    path: AppRoutes.authRegister,
    name: 'AuthRegister',
    component: AuthRegister,
    meta: { layout: 'blank', auth: false }
  },
  {
    path: AppRoutes.authLogin,
    name: 'AuthLogin',
    component: AuthLogin,
    meta: { layout: 'blank', auth: false }
  },
  {
    path: AppRoutes.configAddServer,
    name: 'ConfigAddServer',
    component: ConfigAddServer,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.dashboard,
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.serverStrategies,
    name: 'ServerStrategies',
    component: ServerStrategies,
    props: true,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.serverAccounts,
    name: 'ServerAccounts',
    component: ServerAccounts,
    props: true,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.serverMonitoring,
    name: 'ServerMonitoring',
    component: ServerMonitoring,
    props: true,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.serverExchangeWebsockets,
    name: 'ServerExchangeWebsockets',
    component: ServerExchangeWebsockets,
    props: true,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.serverKlines,
    name: 'ServerKlines',
    component: ServerKlines,
    props: true,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.serverStrategyDetails,
    name: 'ServerStrategyDetails',
    component: ServerStrategyDetails,
    props: true,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.users,
    name: 'Users',
    component: Users,
    meta: { layout: 'default', auth: true }
  },
  {
    path: AppRoutes.health,
    name: 'Health',
    component: Health,
    meta: { layout: 'default', auth: true }
  },
  {
    path: '/debug',
    name: 'Debug',
    component: Debug,
    meta: { layout: 'default', auth: true }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === AppRoutes.dashboard) {
    next()
  }

  AuthService.isAuthenticated().then(isAuthenticated => {
    if (to.name === 'AuthLogin' && isAuthenticated) {
      // redirect from login page to dashboard page if user is authenticated
      next(AppRoutes.splashScreen)
    } else if (to.meta.auth && !isAuthenticated) {
      // redirect to login page is user is not authenticated
      next(AppRoutes.authLogin)
    } else {
      next()
    }
  })
})

export default router
