






































































import moment from 'moment'
import Vue, { PropType } from 'vue'
import StrategiesTableFilters from '@/ui/components/strategies/StrategiesTableFilters.vue'
import { IServerItem } from '@/store/servers/types'
import store from '@/store'
import StrategiesTableOptions from '@/ui/components/strategies/StrategiesTableOptions.vue'
import { IStrategyData } from '@/interfaces/strategy'

export interface IStrategyRow {
  id: string
  product: string
  name: string
  type: string
  account: string
  hasInstance: boolean
  createdAt: string
}

export default Vue.extend({
  name: 'StrategiesTable',
  components: {
    StrategiesTableOptions,
    StrategiesTableFilters
  },
  props: {
    serverId: {
      type: String,
      required: true
    },
    strategies: {
      type: Array as PropType<Array<IStrategyData>>
    },
    checkedRows: {
      type: Array as PropType<Array<IStrategyRow>>,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      filters: {
        products: [] as Array<string>,
        strategies: [] as Array<string>,
        instance: null as boolean | null
      },
      options: {
        itemsPerPage: 50
      },
      currentPage: 1
    }
  },
  computed: {
    serverItem (): IServerItem | undefined {
      return store.state.servers.list.find(s => s.server._id === this.serverId)
    },
    dataStrategies (): Array<IStrategyRow> {
      if (!this.serverItem) {
        return []
      }

      return this.strategies.map(strategy => {
        const account = this.serverItem ? this.serverItem.accounts.find(a => a._id === strategy.entity.accountID) : undefined

        return {
          id: strategy.entity._id,
          product: strategy.entity.productSymbol.replace('USD', ''),
          name: strategy.entity.name,
          type: strategy.entity.strategyName,
          account: account ? account.name : '',
          hasInstance: strategy.state.hasInstance,
          createdAt: moment((strategy.entity as any).createdAt).format('DD/MM/YYYY')
        }
      })
    },
    filteredStrategies (): Array<IStrategyRow> {
      return this.dataStrategies
        .filter(s => !this.filters.products.length || this.filters.products.includes(s.product))
        .filter(s => !this.filters.strategies.length || this.filters.strategies.includes(s.type))
        .filter(s => this.filters.instance === null || s.hasInstance === this.filters.instance)
    },
    productFilterValues (): Array<string> {
      return [...new Set(this.dataStrategies.map(s => s.product))]
    },
    strategyFilterValues (): Array<string> {
      return [...new Set(this.dataStrategies.map(s => s.type))]
    }
  },
  methods: {
    onActionView (strategyId: string) {
      this.$router.push(`/server/${this.serverId}/strategies/${strategyId}`)
    },
    onActionCreateInstance (strategyId: string) {
      this.$buefy.dialog.confirm({
        title: 'Creating Strategy Instance',
        message: 'Are you sure you want to <b>create</b> strategy instance?',
        confirmText: 'Create Instance',
        type: 'is-success',
        hasIcon: true,
        onConfirm: () => { this.$emit('create-instance', strategyId) }
      })
    },
    onActionDelete (strategyId: string) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Strategy',
        message: 'Are you sure you want to <b>delete</b> strategy? This action cannot be undone.',
        confirmText: 'Delete Strategy',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => { this.$emit('delete', strategyId) }
      })
    },
    onCheckRow (strategies: Array<any>) {
      this.$emit('update:checkedRows', strategies)
    }
  }
})
