

































































import Vue from 'vue'
import { TradingServerService } from '@/core/services/trading/trading-server-service'
import { PromiseUtils } from '@/core/utils/promise-utils'
import { AppRoutes } from '@/core/constants'

export default Vue.extend({
  name: 'ConfigAddServer',
  components: {},
  data () {
    return {
      serverInfo: {
        name: '',
        description: '',
        exchange: '',
        url: ''
      },
      connectError: '',
      isConnecting: false,
      addError: '',
      isAdding: false
    }
  },
  computed: {
    isServerUrlDisabled (): boolean {
      return (!!this.serverInfo.name && !this.connectError) || this.isConnecting
    },
    isConnectDisabled (): boolean {
      return this.serverInfo.url.length <= 14 || this.isServerUrlDisabled
    },
    isAddDisabled (): boolean {
      return this.serverInfo.description.length <= 3 || !this.serverInfo.exchange
    }
  },
  methods: {
    async onConnect () {
      if (this.isConnectDisabled) {
        return
      }

      this.connectError = ''
      this.isConnecting = true

      try {
        const serverInfo = await PromiseUtils
          .minimumDelayPromise(TradingServerService.serverInfo(this.serverInfo.url), 500)

        if (serverInfo.initialized) {
          this.connectError = 'Server is already initialized'
        } else {
          this.serverInfo.name = serverInfo.name
        }
      } catch (error) {
        this.connectError = error.message
      }

      this.isConnecting = false
    },
    async onAdd () {
      if (this.isAddDisabled) {
        return
      }

      this.addError = ''
      this.isAdding = true

      try {
        // initialize server request
        await PromiseUtils
          .minimumDelayPromise(TradingServerService.serverInitialize(this.serverInfo), 500)

        // redirect to home page on success
        await this.$router.replace(AppRoutes.splashScreen)
      } catch (error) {
        this.addError = error.message
      }

      this.isAdding = false
    }
  }
})
