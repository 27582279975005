export enum UserRoles {
  ADMIN = 'admin',
  MAINTAINER = 'maintainer'
}

export interface IUserEntity {
  _id?: any
  username: string,
  password: string,
  role: string
}
