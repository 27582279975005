












































import Vue from 'vue'
import store from '@/store'
import { AuthService } from '@/core/services/ihm/ihm-auth-service'
import { AppRoutes } from '@/core/constants'
import { PromiseUtils } from '@/core/utils/promise-utils'
import { AuthActions } from '@/store/auth/types'
import { Route } from 'vue-router'

// TODO: add sessions expired message

export default Vue.extend({
  name: 'AuthInitialize',
  components: {},
  data () {
    return {
      previousRoute: undefined as Route | undefined,
      authForm: {
        username: '',
        password: ''
      },
      errorMessage: '',
      isLoading: false
    }
  },
  computed: {
    isDisabled (): boolean {
      return this.authForm.username.length === 0 && this.authForm.password.length === 0
    }
  },
  async created () {
    if (store.state.auth.authenticated) {
      await this.$router.replace(AppRoutes.splashScreen)
    }
  },
  methods: {
    async onLogin () {
      if (this.isDisabled) {
        return
      }

      this.errorMessage = ''
      this.isLoading = true

      try {
        // login user
        const userInfo = await PromiseUtils.minimumDelayPromise(AuthService.authenticateUser(this.authForm), 500)
        // update store
        await store.dispatch(AuthActions.LOGIN_USER, userInfo)

        // redirect to splashscreen
        if (this.previousRoute) {
          await this.$router.replace(this.previousRoute.path)
        } else {
          await this.$router.replace(AppRoutes.splashScreen)
        }

      } catch (error) {
        this.errorMessage = error.message
      }

      this.isLoading = false
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.name) {
      next(vm => {
        (vm as any).previousRoute = from
      })
    } else {
      next()
    }
  }
})
