import { IhmHttpClient } from '@/core/http/ihm-http-client'
import { IConfigBootstrapResponse } from '@/core/http/interfaces/ihm-requests'

export class IhmConfigService {
  /**
   * Fetch IHM server bootstrap config
   */
  public static async configBootstrap(): Promise<IConfigBootstrapResponse> {
    const response = await IhmHttpClient.configBootstrap()

    return response.data
  }
}
