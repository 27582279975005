import { TradingHttpClient } from '@/core/http/trading-http-client'
import {
  ICreateStrategyTradingRequest,
  IStrategiesDefinitionsTradingResponse
} from '@/core/http/interfaces/trading-requests'
import { IStrategyEntity } from '@/core/http/remote-interfaces/persistence-remote'

export class TradingStrategiesServices {
  public static async strategiesDefinitions(serverUrl: string): Promise<IStrategiesDefinitionsTradingResponse> {
    const response = await TradingHttpClient.strategiesDefinitions(serverUrl)

    return response.data
  }

  public static async createStrategyEntity(serverUrl: string, request: ICreateStrategyTradingRequest): Promise<Array<IStrategyEntity>> {
    const response = await TradingHttpClient.createStrategyEntity(serverUrl, request)

    return response.data
  }

  public static async deleteStrategyEntity(serverUrl: string, id: string): Promise<IStrategyEntity> {
    const response = await TradingHttpClient.deleteStrategyEntity(serverUrl, id)

    return response.data
  }

  public static async createStrategyInstance(serverUrl: string, id: string): Promise<object> {
    const response = await TradingHttpClient.createStrategyInstance(serverUrl, id)

    return response.data
  }
  public static async deleteStrategyInstance(serverUrl: string, id: string): Promise<object> {
    const response = await TradingHttpClient.deleteStrategyInstance(serverUrl, id)

    return response.data
  }

  public static async deleteStrategiesInstance(serverUrl: string): Promise<object> {
    const response = await TradingHttpClient.deleteStrategiesInstance(serverUrl)

    return response.data
  }

  public static async clearDatabase(serverUrl: string): Promise<void> {
    const response = await TradingHttpClient.clearDatabase(serverUrl)
  }
}
