


























































import Vue from 'vue'
import store from '@/store'
import SimpleMetricCard from '@/ui/components/metrics/SimpleMetricCard.vue'
import { IServerItem } from '@/store/servers/types'
import { TradingMonitoringService } from '@/core/services/trading/trading-monitoring-service'
import { groupBy, orderBy } from 'lodash'
import { IMonitorStateLoopData } from '@/interfaces/monitor-state'
import { ITrade } from '@/interfaces/trade'
import {
  IMonitorStateClosingEntity,
  IMonitorStateOpeningEntity,
  IMonitorStateRunningEntity
} from '@/core/http/remote-interfaces/persistence-remote'
import moment from 'moment'

export default Vue.extend({
  name: 'Home',
  components: { SimpleMetricCard },
  data () {
    return {
      trades: [] as Array<ITrade>,
      loaded: false
    }
  },
  async mounted () {
    await this.fetchTrades()
  },
  computed: {
    servers (): Array<IServerItem> {
      return store.state.servers.list
    },
    serversLength (): number {
      return this.servers.length
    },
    accountsLength (): number {
      return this.servers.map(x => x.accounts.length).reduce((p, c) => p + c)
    },
    totalROI (): number {
      return this.trades.reduce((acc, t) => {
        if (!t.monitorStates.closing || !t.monitorStates.running || !t.monitorStates.running.order) return acc
        return acc + (t.monitorStates.closing.pnl / t.monitorStates.running.maxLossAllowed)
      }, 0) / this.trades.length
    },
    totalPnl (): number {
      return this.trades.reduce((acc, val) => {
        if (!val.monitorStates.closing) {
          return acc
        }
        return acc + val.monitorStates.closing.pnl
      }, 0)
    },
    pnlColor (): string {
      if (!this.totalPnl || !this.totalPnl) {
        return '#b9b9c3'
      }

      return this.totalPnl > 0 ? '#28c76f' : '#ea5455'
    }
  },
  filters: {
    moment: (value: number): string => {
      return moment(value).format('MM-DD HH:mm')
    },
    removeUSD: (value: string): string => {
      return value.slice(0, -3)
    },
    formatLabel: (value: string): string => {
      const start = value.indexOf('-', value.indexOf('-') + 1) + 2
      return value.substr(start, value.indexOf('\n') - start)
    },
    formatUSD: (value: number, scale: number): string => {
      if (scale === 1) {
        return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value * 100) / 100)}`
      } else {
        return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value / 100) / 100)}`
      }
    },
    formatSide: (value: number): string => {
      return value > 0 ? 'Long' : 'Short'
    }
  },
  methods: {
    calculateROI (item: ITrade): number {
      if (!item.monitorStates || !item.monitorStates.closing || !item.monitorStates.running || !item.monitorStates.running.order) {
        return 0
      }

      return item.monitorStates.closing.pnl / item.monitorStates.running.maxLossAllowed
    },
    async fetchTrades () {
      const trades: Array<ITrade> = []
      for (const server of this.servers) {
        try {
          const strategiesEntities = await TradingMonitoringService.getEntities(server.server.url)
          const strategiesStates = await TradingMonitoringService.getStates(server.server.url)

          for (const strategyState of strategiesStates) {
            try {
              const strategyId = strategyState.ID
              const strategyEntity = strategiesEntities.find(e => e._id === strategyId)

              if (!strategyEntity) {
                continue
              }
              const monitorStates = await TradingMonitoringService.getStrategyMonitorStates(server.server.url, strategyId)

              const currentState = await TradingMonitoringService.getState(server.server.url, strategyId)

              if (currentState && currentState.stateMonitor) {
                monitorStates.push(currentState.stateMonitor as IMonitorStateClosingEntity | IMonitorStateOpeningEntity | IMonitorStateRunningEntity)
              }

              const monitorStatesMap = groupBy(monitorStates, 'loopID')

              for (const loopID in monitorStatesMap) {
                const monitorStateLoop: IMonitorStateLoopData = {
                  id: loopID,
                  opening: monitorStatesMap[loopID].find(
                    (m) => m.stateType === 'MonitorStateOpening'
                  ) as IMonitorStateOpeningEntity,
                  running: monitorStatesMap[loopID].find(
                    (m) => m.stateType === 'MonitorStateRunning'
                  ) as IMonitorStateRunningEntity,
                  closing: monitorStatesMap[loopID].find(
                    (m) => m.stateType === 'MonitorStateClosing'
                  ) as IMonitorStateClosingEntity
                }
                if (monitorStateLoop.running !== undefined && monitorStateLoop.closing !== undefined && monitorStateLoop.closing.endTimestamp !== 0) {
                  trades.push({
                    monitorStates: monitorStateLoop,
                    strategyEntity: strategyEntity,
                    product: server.products[strategyEntity.productSymbol]
                  })
                }
              }
            } catch (error) {
              console.log(error)
            }
          }
          this.loaded = true
          this.trades = orderBy(trades, 'monitorStates.closing.endTimestamp', 'desc')
        } catch (error) {
          console.log(error.message)
        }
      }
    }
  }
})
