























































































import Vue from "vue";
import { TradingKlinesService } from "@/core/services/trading/trading-klines-service";
import { IServerItem } from "@/store/servers/types";
import store from "@/store";
import {
  IKline,
  KlineInterval,
} from "@/core/http/remote-interfaces/exchanges-remote";
import { IExchangeKlineState } from '@/core/http/interfaces/trading-requests';

export default Vue.extend({
  name: "ServerKlines",
  components: {},
  props: {
    serverID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        symbol: "",
        interval: "",
        from: undefined as undefined | number,
        to: undefined as undefined | number,
      },
      intervals: [
        // KlineInterval
        "1m",
        "5m",
        "15m",
        "30m",
        "1h",
        "4h",
        "1d",
      ],
      klines: [] as Array<IKline>,
      state: undefined as IExchangeKlineState | undefined,
      errorMessage: "",
    };
  },
  computed: {
    serverItem(): IServerItem | undefined {
      return store.state.servers.list.find(
        (s) => s.server._id === this.serverID
      );
    },
  },
  async mounted() {
    if (!this.serverItem) {
      return;
    }
    this.state = await TradingKlinesService.getState(
      this.serverItem.server.url
    );
  },
  async beforeDestroy() {},

  methods: {
    async onSyncAll() {
      if (!this.serverItem) {
        return;
      }
      await TradingKlinesService.syncAll(this.serverItem.server.url);
    },
    async onCancelSync() {
      if (!this.serverItem) {
        return;
      }
      await TradingKlinesService.cancelSync(this.serverItem.server.url);
    },
    async onCheckSync() {
      if (!this.serverItem) {
        return;
      }
      this.state = await TradingKlinesService.getState(
        this.serverItem.server.url
      );
    },
    async onFetch() {
      if (!this.serverItem || !this.form.from || !this.form.to) {
        return;
      }
      try {
        this.errorMessage = "";
        this.klines = await TradingKlinesService.fetchKlines(
          this.serverItem.server.url,
          this.form.symbol,
          new Date(this.form.from).getTime(),
          new Date(this.form.to).getTime(),
          KlineInterval[this.form.interval as any] as unknown as number
        );
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
});
