














































import Vue, { PropType } from 'vue'
import { IMonitorStateLoopData } from '@/interfaces/monitor-state'
import SimpleMetricField from '@/ui/components/metrics/SimpleMetricField.vue'
import RunningPriceBar from '@/ui/components/monitor-states/RunningPriceBar.vue'
import moment from 'moment'
import { IProduct } from '@/interfaces/strategy'

export default Vue.extend({
  name: 'RunningMonitorState',
  components: { RunningPriceBar, SimpleMetricField },
  props: {
    product: {
      type: Object as PropType<IProduct>,
      required: true
    },
    state: {
      type: Object as PropType<IMonitorStateLoopData>,
      required: true
    },
    isRunning: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {}
  },
  filters: {
    formatUSD: (value: number, scale: number): string => {
      if (scale === 1) {
        return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value * 100) / 100)}`
      } else {
        return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value / 100) / 100)}`
      }
    },
    formatPNL: (value: number, scale: number): string => {
      if (scale === 1) {
        return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value * 100) / 100)}`
      } else {
        return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value / 100) / 100)}`
      }
    }
  },
  computed: {
    positionValue (): number {
      if (!this.state.running) {
        return 0
      }
      return this.state.running.entryPrice * this.state.running.filledContracts * this.product.currencyScale * this.product.contractSize
    },
    quantity (): number {
      if (!this.state.running) {
        return 0
      }
      return this.state.running.filledContracts * this.product.contractSize
    },
    duration (): number {
      if (!this.state.running) {
        return 0
      }

      return (this.state.running.endTimestamp || Date.now()) - this.state.running.startTimestamp
    },
    startDate (): string {
      if (!this.state.running) {
        return ''
      }

      return moment(this.state.running.startTimestamp).toISOString()
    },
    pnlColor (): string {
      if (!this.state.running || !this.state.running.unrealisedPnl) {
        return '#b9b9c3'
      }

      return this.state.running.unrealisedPnl > 0 ? '#28c76f' : '#ea5455'
    }
  },
  methods: {
    onDetailsClick (): void {
      this.$emit('details-click', this.state.running)
    },
    onChartClick (): void {
      this.$emit('chart-click', this.state)
    }
  }
})
