import { ActionTree } from 'vuex'
import { AuthActions, AuthMutations, IAuthState } from '@/store/auth/types'
import { IRootState } from '@/store/types'
import { IAuthUserIhmResponse } from '@/core/http/interfaces/ihm-requests'
import jwt from 'jsonwebtoken'

export const actions: ActionTree<IAuthState, IRootState> = {
  async [AuthActions.LOGIN_USER](context, response: IAuthUserIhmResponse) {
    context.commit(AuthMutations.SET_USER, response.user)
    context.commit(AuthMutations.SET_TOKEN, response.token)
    context.commit(AuthMutations.SET_TOKEN_INFO, jwt.decode(response.token))
    context.commit(AuthMutations.SET_AUTHENTICATED, true)
  },

  async [AuthActions.LOGOUT_USER](context) {
    context.commit(AuthMutations.SET_USER, undefined)
    context.commit(AuthMutations.SET_TOKEN, undefined)
    context.commit(AuthMutations.SET_TOKEN_INFO, undefined)
    context.commit(AuthMutations.SET_AUTHENTICATED, undefined)
  }
}
