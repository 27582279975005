








import Vue, { PropType } from 'vue'
import { IStrategyConfigSchema } from '@/core/http/interfaces/trading-requests'

export default Vue.extend({
  name: 'schema-boolean',
  props: {
    name: {
      type: String
    },
    schema: {
      type: Object as PropType<IStrategyConfigSchema>
    },
    value: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showType: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    example (): boolean | undefined {
      return this.schema.examples ? this.schema.examples[0] : undefined
    }
  },
  created () {
    if (this.value === undefined) {
      this.$emit('input', this.example !== undefined ? this.example : false)
    }
  },
  methods: {
    onInput (value: boolean) {
      this.$emit('input', value)
    }
  },
  watch: {
    value (newValue: boolean | undefined) {
      if (newValue === undefined) {
        this.$emit('input', this.example !== undefined ? this.example : false)
      }
    }
  }
})
