













import Vue, { PropType } from 'vue'
import TradingVue from 'trading-vue-js'
import { IMonitorStateLoopData } from '@/interfaces/monitor-state'
import { IServerItem } from '@/store/servers/types'
import { IStrategyData } from '@/interfaces/strategy'

export default Vue.extend({
  name: 'MonitorStateChartModal',
  components: {
    TradingVue
  },
  props: {
    server: {
      type: Object as PropType<IServerItem>,
      required: true
    },
    strategy: {
      type: Object as PropType<IStrategyData>,
      required: true
    },
    state: {
      type: Object as PropType<IMonitorStateLoopData>,
      required: false
    }
  },
  data () {
    return {
      chartData: {
        ohlcv: [
          [1551128400000, 33, 37.1, 14, 14, 196],
          [1551132000000, 13.7, 30, 6.6, 30, 206],
          [1551135600000, 29.9, 33, 21.3, 21.8, 74],
          [1551139200000, 21.7, 25.9, 18, 24, 140],
          [1551142800000, 24.1, 24.1, 24, 24.1, 29]
        ]
      }
    }
  },
  async mounted () {
    // if (!this.state.opening) {
    //   return
    // }

    if (this.state && this.state.opening && this.state.opening.startTimestamp && this.server.server.url && this.strategy.entity.productSymbol) {
      console.log('TODO fetch klines for chart')
      // const klines = await TradingKlinesService.fetchKlines(
      //   this.server.server.url,
      //   this.strategy.entity.productSymbol,
      //   this.state.opening.startTimestamp,
      //   new Date().getTime(),
      //   KlineInterval['1m']
      // )
      // const startTime = this.state.opening.startTimestamp
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
})
