


























import Vue, { PropType } from 'vue'
import { IMonitorStateRunningEntity } from '@/core/http/remote-interfaces/persistence-remote'

export default Vue.extend({
  name: 'RunningPriceBar',
  props: {
    state: {
      type: Object as PropType<IMonitorStateRunningEntity>,
      required: true
    }
  },
  methods: {
    formatPrice: (value: number): string => {
      if (!value) return '$0.00'

      return `$${value.toFixed(3)}`
    }
  },
  filters: {
    formatPrice: (value: number): string => {
      if (!value) return '$0.00'

      return `$${value.toFixed(2)}`
    }
  },
  computed: {
    isShown (): boolean {
      return !!this.state.stopLossPrice && !!this.state.takeProfitPrice && !!this.state.entryPrice && !!this.state.currentPrice
    },
    lower (): number {
      return Math.min(this.state.stopLossPrice, this.state.takeProfitPrice)
    },
    higher (): number {
      return Math.max(this.state.stopLossPrice, this.state.takeProfitPrice)
    },
    sliderValue: {
      get: function (): number[] {
        return [this.state.entryPrice, this.state.currentPrice]
      },
      set: (): void => {}
    },
    sliderType (): string {
      if (this.state.stopLossPrice < this.state.takeProfitPrice) {
        return (this.state.currentPrice >= this.state.entryPrice) ? 'is-success' : 'is-danger'
      } else {
        return (this.state.currentPrice <= this.state.entryPrice) ? 'is-success' : 'is-danger'
      }
    }
  }
})
