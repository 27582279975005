import { IAccountEntity, IServerEntity } from '@/core/http/remote-interfaces/persistence-remote'
import { IStrategyDefinition } from '@/core/http/interfaces/trading-requests'
import { IProduct } from '@/core/http/remote-interfaces/exchanges-remote'

export interface IServerItem {
  server: IServerEntity
  definitions: Array<IStrategyDefinition>
  products: Record<string, IProduct>
  accounts: Array<IAccountEntity>
}

export interface IServersState {
  list: Array<IServerItem>
}

export enum ServersMutations {
  ADD_SERVER = 'ADD_SERVER',
  ADD_SERVER_ACCOUNT = 'ADD_SERVER_ACCOUNT',
  REMOVE_SERVER_ACCOUNT = 'REMOVE_SERVER_ACCOUNT',
  CLEAR_SERVER = 'CLEAR_SERVER',
}
