



























































































































import Vue from "vue";
import { IAccountEntity } from "@/core/http/remote-interfaces/persistence-remote";
import { TradingAccountsService } from "@/core/services/trading/trading-accounts-service";
import { PromiseUtils } from "@/core/utils/promise-utils";
import store from "@/store";
import { IServerItem, ServersMutations } from "@/store/servers/types";

export default Vue.extend({
  name: "ServerAccounts",
  props: {
    serverID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      createModal: {
        active: false,
        error: "",
        loading: false,
        form: {
          name: "",
          apiID: "",
          apiKey: "",
        } as Pick<IAccountEntity, "name" | "apiID" | "apiKey">,
      },
    };
  },
  computed: {
    serverItem(): IServerItem | undefined {
      return store.state.servers.list.find(
        (s) => s.server._id === this.serverID
      );
    },
    createDisabled(): boolean {
      return (
        this.createModal.form.name.length < 4 ||
        this.createModal.form.apiKey.length < 10 ||
        this.createModal.form.apiID.length < 10
      );
    },
  },
  async mounted() {
    if (!this.serverItem) {
      return;
    }
  },
  methods: {
    onActionAddAccount() {
      this.createModal.active = true;
    },
    async onCreateAccount() {
      if (this.createDisabled) {
        return;
      }

      if (!this.serverItem) {
        return;
      }

      this.createModal.error = "";
      this.createModal.loading = true;

      try {
        // add server account
        const account = await PromiseUtils.minimumDelayPromise(
          TradingAccountsService.createAccount(
            this.serverItem.server.url,
            this.createModal.form
          ),
          500
        );

        // update store
        store.commit(ServersMutations.ADD_SERVER_ACCOUNT, {
          serverID: this.serverID,
          account: account,
        });
        this.onCreateModalClose();
      } catch (error) {
        this.createModal.error = error.message;
      }

      this.createModal.loading = false;
    },
    onCreateModalClose() {
      this.createModal.active = false;
      this.createModal.form.name = "";
      this.createModal.form.apiID = "";
      this.createModal.form.apiKey = "";
      this.createModal.error = "";
    },
    onActionDeleteAccount(account: IAccountEntity) {
      this.$buefy.dialog.confirm({
        title: "Deleting account",
        message:
          "Are you sure you want to <b>delete</b> account? This action cannot be undone.",
        confirmText: "Delete Account",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.onDeleteAccount(account);
        },
      });
    },
    async onDeleteAccount(account: IAccountEntity) {
      if (!this.serverItem) {
        return;
      }

      try {
        // delete account from server
        await TradingAccountsService.deleteAccount(
          this.serverItem.server.url,
          account._id
        );

        // update store
        store.commit(ServersMutations.REMOVE_SERVER_ACCOUNT, {
          serverID: this.serverID,
          account: account,
        });

        // show success notification
        this.$buefy.toast.open({
          duration: 3000,
          message: "Account successfully deleted",
          type: "is-success",
        });
      } catch (error) {
        // show error notification
        this.$buefy.toast.open({
          duration: 3000,
          message: error.message,
          type: "is-danger",
        });
      }
    },
    async onVerifyAccount(account: IAccountEntity) {

      if (!this.serverItem) {
        return;
      }

      try {
        // delete account from server
        await TradingAccountsService.verifyAccount(
          this.serverItem.server.url,
          account._id
        );

        // show success notification
        this.$buefy.toast.open({
          duration: 3000,
          message: "Account is fine",
          type: "is-success",
        });
      } catch (error) {
        // show error notification
        this.$buefy.toast.open({
          duration: 3000,
          message: error.message,
          type: "is-danger",
        });
      }
    },
  },
});
