import { TradingHttpClient } from '@/core/http/trading-http-client'
import {
  IServerInfoTradingResponse,
  IServerInitializeTradingRequest,
  IServerInitializeTradingResponse
} from '@/core/http/interfaces/trading-requests'

export class TradingServerService {
  public static async serverInfo(serverUrl: string): Promise<IServerInfoTradingResponse> {
    const response = await TradingHttpClient.serverInfo(serverUrl)

    return response.data
  }

  public static async serverInitialize(request: IServerInitializeTradingRequest): Promise<IServerInitializeTradingResponse> {
    const response = await TradingHttpClient.serverInitialize(request)

    return response.data
  }
}
