















import Vue, { PropType } from 'vue'
import { IStrategyConfigSchema } from '@/core/http/interfaces/trading-requests'
import JsonInput from '@/ui/components/common/JsonInput.vue'

export default Vue.extend({
  name: 'schema-any',
  components: { JsonInput },
  props: {
    name: {
      type: String
    },
    schema: {
      type: Object as PropType<IStrategyConfigSchema>
    },
    value: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showType: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isInvalid: false
    }
  },
  computed: {
    example (): number | undefined {
      return this.schema.examples ? this.schema.examples[0] : undefined
    }
  },
  methods: {
    onInput (value: string) {
      this.$emit('input', value)
    }
  }
})
