import { IhmHttpClient } from '@/core/http/ihm-http-client'
import localforage from 'localforage'
import { IServerEntity } from '@/core/http/remote-interfaces/persistence-remote'

const DB_KEY_SERVERS_LIST = 'servers-list'

export class IhmServersService {
  public static async fetchServers (): Promise<Array<IServerEntity>> {
    const response = await IhmHttpClient.servers()

    return response.data
  }

  public static async getServersList (): Promise<Array<IServerEntity>> {
    const serversList = await localforage.getItem(DB_KEY_SERVERS_LIST)

    if (!serversList) {
      return []
    }

    return serversList as Array<IServerEntity>
  }

  public static async getServerByID (serverID: string): Promise<IServerEntity | undefined> {
    const serversList = await this.getServersList()

    return serversList.find(server => server._id === serverID)
  }
}
