




























































































import Vue from "vue";
import {
  IUserEntity,
  UserRoles,
} from "@/core/http/remote-interfaces/persistence-remote";
import { IUserIhmResponse } from "@/core/http/interfaces/ihm-requests";
import { IhmUsersService } from "@/core/services/ihm/ihm-users-service";

export default Vue.extend({
  name: "Users",
  components: {},
  async created() {
    await this.getUsers();
  },
  data(): {
    users: Array<IUserIhmResponse>;
    errorMessage: string;
    showModal: boolean;
    userForm: IUserEntity;
    roles: Array<string>;
  } {
    return {
      users: [],
      errorMessage: "",
      showModal: false,
      userForm: {
        username: "",
        password: "",
        role: "",
      },
      roles: [UserRoles.MAINTAINER, UserRoles.ADMIN],
    };
  },
  methods: {
    openUserCreationForm: async function () {
      this.showModal = true;
    },
    cancelUserCreation: function () {
      this.showModal = false;
    },
    proceedUserCreation: async function () {
      try {
        this.errorMessage = "";
        await IhmUsersService.createUser(this.userForm);
        await this.getUsers();
      } catch (error) {
        this.errorMessage = error.message;
      }
      this.userForm = {
        username: "",
        password: "",
        role: "",
      };
      this.showModal = false;
    },
    onRemoveUser: async function (id: any) {
      try {
        this.errorMessage = "";
        await IhmUsersService.deleteUser(id);
      } catch (error) {
        this.errorMessage = error.message;
      }
      await this.getUsers();
    },
    getUsers: async function () {
      try {
        this.errorMessage = "";
        this.users = await IhmUsersService.getUsers();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
});
