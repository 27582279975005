































































































import Vue from 'vue'
import SchemaObject from '@/ui/components/schema/schema-object.vue'
import { IServerItem } from '@/store/servers/types'
import store from '@/store'
import { IStrategyConfig } from '@/core/http/remote-interfaces/trading-remote'
import {
  ICreateStrategyTradingRequest,
  IStrategyConfigSchema
} from '@/core/http/interfaces/trading-requests'

export default Vue.extend({
  name: 'CreateStrategyModal',
  components: { SchemaObject },
  model: {
    prop: 'active',
    event: 'update:active'
  },
  props: {
    serverId: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        name: '',
        strategyName: '',
        productSymbol: [],
        accountID: '',
        archived: false,
        config: {
          openingRisk: 0,
          opening: {},
          running: {}
        } as IStrategyConfig,
        pnl: 0,
        trades: 0
      } as ICreateStrategyTradingRequest
    }
  },
  computed: {
    serverItem (): IServerItem | undefined {
      return store.state.servers.list.find(
        (s) => s.server._id === this.serverId
      )
    },
    currentConfigSchema (): IStrategyConfigSchema | undefined {
      if (!this.serverItem) {
        return
      }

      if (!this.form.strategyName) {
        return
      }

      const definition = this.serverItem.definitions.find(
        (d) => d.name === this.form.strategyName
      )

      return definition ? definition.configSchema : undefined
    },
    disabled (): boolean {
      if (
        this.form.name.length === 0 ||
        this.form.strategyName.length === 0 ||
        this.form.productSymbol.length === 0 ||
        this.form.accountID.length === 0 ||
        this.form.config.openingRisk == undefined ||
        this.form.config.running == undefined ||
        this.form.config.opening == undefined
      ) {
        return true
      }
      // return false
      // non récursif
      return (
        Object.values(this.form.config.running).some((x) => x == undefined) ||
        Object.values(this.form.config.opening).some((x) => x == undefined)
      )
    }
  },
  mounted () {},
  methods: {
    onUpdateActive (value: boolean) {
      this.$emit('update:active', value)
    },
    onSubmit () {
      if (this.disabled) {
        return
      }

      this.$emit('submit', this.form)
    },
    onStrategyChange () {
      // TODO move to helper
      if (!this.currentConfigSchema || !this.currentConfigSchema.keys) {
        return
      }

      const configSchemaKeys = this.currentConfigSchema.keys

      // const emptyConfig = {
      //   risk: undefined,
      //   opening: {},
      //   running: {},
      // } as Record<string, any>;

      // Object.keys(configSchemaKeys).forEach((key) => {
      //   const subSchema = configSchemaKeys[key];
      //   if (subSchema && subSchema.keys && subSchema.type === "object") {
      //     Object.keys(subSchema.keys).forEach((subKey) => {
      //       emptyConfig[key][subKey] = undefined;
      //     });
      //   }
      // });

      const recursiveScan = function (result: Record<string, any>, schema: any) {
        const keys = Object.keys(schema)
        for (let i = 0; i < keys.length; i++) {
          const child = schema[keys[i]]
          if (child && child.keys && child.type === 'object') {
            // need another recursive scan
            result[keys[i]] = {}
            recursiveScan(result[keys[i]], child.keys)
          } else {
            result[keys[i]] = undefined
          }
        }
      }

      const emptyConfig = {} as Record<string, any>
      recursiveScan(emptyConfig, configSchemaKeys)

      this.form.config = emptyConfig as IStrategyConfig
    }
  }
})
