import { IhmHttpClient } from '@/core/http/ihm-http-client'
import { IUserEntity } from '@/core/http/remote-interfaces/persistence-remote'
import { IUserIhmResponse } from '@/core/http/interfaces/ihm-requests'

export class IhmUsersService {

  public static async getUsers(): Promise<Array<IUserIhmResponse>> {
    const response = await IhmHttpClient.getUsers()
    return response.data
  }

  public static async getUser(id: string): Promise<IUserIhmResponse> {
    const response = await IhmHttpClient.getUser(id)
    return response.data
  }

  public static async deleteUser(id: string): Promise<void> {
    const response = await IhmHttpClient.deleteUser(id)
  }

  public static async createUser(request: Omit<IUserEntity, 'id'>): Promise<IUserIhmResponse> {
    const response = await IhmHttpClient.createUser(request)
    return response.data
  }

}
