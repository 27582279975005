








































import Vue, { PropType } from 'vue'
import SchemaNumber from '@/ui/components/schema/schema-number.vue'
import SchemaBoolean from '@/ui/components/schema/schema-boolean.vue'
import SchemaAny from '@/ui/components/schema/schema-any.vue'
import { IStrategyConfigSchema } from '@/core/http/interfaces/trading-requests'

export default Vue.extend({
  name: 'schema-object',
  components: { SchemaBoolean, SchemaNumber, SchemaAny },
  props: {
    isRoot: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String
    },
    schema: {
      type: Object as PropType<IStrategyConfigSchema>
    },
    value: {
      type: [Object, Number, String],
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showType: {
      type: Boolean,
      default: true
    }
  }
})
