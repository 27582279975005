






























import Vue, { PropType } from 'vue'
import * as jdenticon from 'jdenticon'
import { AppRoutes } from '@/core/constants'
import store from '@/store'
import { AuthActions } from '@/store/auth/types'
import { IUserEntity } from '@/core/http/remote-interfaces/persistence-remote'

export default Vue.extend({
  name: 'AppTopbar',
  props: {
    burgerActive: {
      type: Boolean
    }
  },
  data () {
    return {}
  },
  computed: {
    user (): IUserEntity | undefined {
      return store.state.auth.user as IUserEntity
    },
    userAvatar (): string {
      if (!this.user) {
        return ''
      }
      return jdenticon.toSvg(this.user.username, 48, { backColor: '#f5f6fa' })
    }
  },
  methods: {
    onBurgerClick () {
      this.$emit('burger-click')
    },
    async onLogout () {
      await store.dispatch(AuthActions.LOGOUT_USER)
    }
  }
})
