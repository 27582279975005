








import Vue from 'vue'
import BlankLayout from '@/ui/layouts/BlankLayout.vue'
import DefaultLayout from '@/ui/layouts/DefaultLayout.vue'

// TODO Update version after modifications
Vue.prototype.$version = 'v0.7.2b\n(31/08/23)'

export default Vue.extend({
  name: 'App',
  components: {
    BlankLayout,
    DefaultLayout
  },
  computed: {
    layoutComponent (): string {
      // console.log(this.$route)
      return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'blank-layout'
    }
  }
})
