import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { IAuthState } from '@/store/auth/types'
import { getters } from '@/store/auth/getters'
import { mutations } from '@/store/auth/mutations'
import { actions } from '@/store/auth/actions'

const state: IAuthState = {
  initialized: undefined,
  authenticated: undefined,
  user: undefined,
  token: undefined,
  tokenInfo: undefined
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
} as Module<IAuthState, IRootState>
