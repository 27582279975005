import { TradingHttpClient } from '@/core/http/trading-http-client'
import { IStrategyState } from '@/core/http/remote-interfaces/trading-remote'
import { IExchangeWebsocketInfo } from '@/core/http/remote-interfaces/exchanges-remote'
import { IMonitorStateEntity, IStrategyEntity } from '@/core/http/remote-interfaces/persistence-remote'

export class TradingMonitoringService {
  public static async getEntities(serverUrl: string): Promise<Array<IStrategyEntity>> {
    const response = await TradingHttpClient.getStrategiesEntities(serverUrl)

    return response.data
  }
  public static async getEntity(serverUrl: string, id: string): Promise<IStrategyEntity> {
    const response = await TradingHttpClient.getStrategiesEntity(serverUrl, id)

    return response.data
  }

  public static async getState(serverUrl: string, id: string): Promise<IStrategyState> {
    const response = await TradingHttpClient.getStrategyState(serverUrl, id)

    return response.data
  }

  public static async getStates(serverUrl: string): Promise<Array<IStrategyState>> {
    const response = await TradingHttpClient.getStrategiesStates(serverUrl)

    return response.data
  }

  public static async getStrategyMonitorStates(serverUrl: string, id: string): Promise<Array<IMonitorStateEntity>> {
    const response = await TradingHttpClient.getStrategyMonitorStates(serverUrl, id)

    return response.data
  }

  public static async getStrategyMonitorStatesAndLoop(serverUrl: string, strategyId: string, loopId:  string | number): Promise<Array<IMonitorStateEntity>> {
    const response = await TradingHttpClient.getStrategyMonitorStatesAndLoop(serverUrl, strategyId, String(loopId))

    return response.data
  }

  public static async getStrategyMonitorStatesFromLoop(serverUrl: string, strategyId: string, loopId: string | number): Promise<Array<IMonitorStateEntity>> {
    const response = await TradingHttpClient.getStrategyMonitorStatesFromLoop(serverUrl, strategyId, String(loopId), true)

    return response.data
  }

  public static async setLoopMode(serverUrl: string, id: string, loopMode: boolean): Promise<void> {
    const response = await TradingHttpClient.setStrategyLoopMode(serverUrl, id, loopMode)
  }
  public static async setLoopModeAll(serverUrl: string, loopMode: boolean): Promise<void> {
    const response = await TradingHttpClient.setStrategiesLoopMode(serverUrl, loopMode)
  }
  public static async start(serverUrl: string, id: string): Promise<void> {
    const response = await TradingHttpClient.strategyStart(serverUrl, id)
  }
  public static async stop(serverUrl: string, id: string): Promise<void> {
    const response = await TradingHttpClient.strategyStop(serverUrl, id)
  }
  public static async startAll(serverUrl: string): Promise<void> {
    const response = await TradingHttpClient.strategiesStart(serverUrl)
  }
  public static async stopAll(serverUrl: string): Promise<void> {
    const response = await TradingHttpClient.strategiesStop(serverUrl)
  }
  public static async stopAllSmoothly(serverUrl: string): Promise<void> {
    const response = await TradingHttpClient.strategiesStopSmoothly(serverUrl)
  }
  public static async getExchangeWebsockets(serverUrl: string): Promise<Array<IExchangeWebsocketInfo>> {
    const response = await TradingHttpClient.getExchangeWebsockets(serverUrl)

    return response.data
  }
}
