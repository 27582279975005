













import Vue from "vue";

export default Vue.extend({
  name: "JsonInput",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      invalid: false,
    };
  },
  computed: {
    inputClass(): Record<string, boolean> {
      return {
        input: true,
        "is-danger": this.invalid,
      };
    },
  },
  methods: {
    onInput() {
      const $input = this.$refs.input as HTMLInputElement;
      try {
        let object = JSON.parse($input.value);
        this.invalid = false;
      } catch (error) {
        this.invalid = true;
      }
      this.$emit("input", $input.value);
    },
  },
});
