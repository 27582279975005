import { HttpClient, IHttpClientRequestConfig } from '@/core/http/base/http-client'
import {
  IAuthUserIhmRequest,
  IAuthUserIhmResponse,
  IConfigBootstrapResponse,
  IUserIhmResponse
} from '@/core/http/interfaces/ihm-requests'
import { AxiosResponse } from 'axios'
import { HealthcheckResult } from '@/core/http/remote-interfaces/healthcheck-remote'
import { IServerEntity, IUserEntity } from '@/core/http/remote-interfaces/persistence-remote'

export class IhmHttpClient extends HttpClient {
  public static async authInitialize (request: IAuthUserIhmRequest): Promise<AxiosResponse<IAuthUserIhmResponse>> {
    return this.doRequest<IAuthUserIhmResponse>({
      method: 'post',
      path: '/auth/initialize',
      auth: false,
      body: request
    })
  }

  public static async authLogin (request: IAuthUserIhmRequest): Promise<AxiosResponse<IAuthUserIhmResponse>> {
    return this.doRequest<IAuthUserIhmResponse>({
      method: 'post',
      path: '/auth/login',
      auth: false,
      body: request
    })
  }

  public static async configBootstrap (): Promise<AxiosResponse<IConfigBootstrapResponse>> {
    return this.doRequest<IConfigBootstrapResponse>({
      method: 'get',
      path: '/config/bootstrap',
      auth: false
    })
  }

  public static async servers (): Promise<AxiosResponse<Array<IServerEntity>>> {
    return this.doRequest<Array<IServerEntity>>({
      method: 'get',
      path: '/servers'
    })
  }

  public static async getUsers (): Promise<AxiosResponse<Array<IUserIhmResponse>>> {
    return this.doRequest<Array<IUserIhmResponse>>({
      method: 'get',
      path: '/users'
    })
  }

  public static async createUser (request: Omit<IUserEntity, 'id'>): Promise<AxiosResponse<IUserIhmResponse>> {
    return this.doRequest<IUserIhmResponse>({
      method: 'post',
      path: '/users',
      body: request
    })
  }

  public static async deleteUser (id: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      method: 'delete',
      path: '/users/' + id
    })
  }

  public static async getUser (id: string): Promise<AxiosResponse<IUserIhmResponse>> {
    return this.doRequest<IUserIhmResponse>({
      method: 'get',
      path: '/users/' + id
    })
  }

  public static async getHealth (): Promise<AxiosResponse<HealthcheckResult>> {
    return this.doRequest<HealthcheckResult>({
      method: 'get',
      path: '/health'
    })
  }

  protected static async doRequest<T> (config: Omit<IHttpClientRequestConfig, 'baseUrl'>): Promise<AxiosResponse<T>> {
    return super.doRequest(config)
  }
}

(window as any).ihmClient = IhmHttpClient
