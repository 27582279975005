














import Vue from 'vue'

export default Vue.extend({
  name: 'SimpleModal',
  props: {
    active: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: false
    }
  },
  methods: {
    onModalClose () {
      this.$emit('close')
    }
  }
})
