










import Vue from 'vue'
import AppSidebar from '@/ui/components/sidebar/AppSidebar.vue'
import AppTopbar from '@/ui/components/topbar/AppTopbar.vue'
import store from '@/store'
import { AppRoutes } from '@/core/constants'

export default Vue.extend({
  name: 'DefaultLayout',
  components: { AppTopbar, AppSidebar },
  data () {
    return {
      sidebarActive: true
    }
  },
  methods: {
    onBurgerClick () {
      this.sidebarActive = !this.sidebarActive
    }
  },
  computed: {
    authenticated () {
      return store.state.auth.authenticated
    }
  },
  watch: {
    authenticated (newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$router.push(AppRoutes.authLogin)
      }
    }
  }
})
