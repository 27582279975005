import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import urlJoin from 'proper-url-join'
import { AuthService } from '@/core/services/ihm/ihm-auth-service'
import { HttpClientError } from '@/core/http/base/http-client-error'

export interface IHttpClientRequestConfig {
  method: Method
  baseUrl?: string,
  path: string,
  auth?: boolean,
  body?: any,
  query?: any
}

export abstract class HttpClient {
  protected static async doRequest<T>(config: IHttpClientRequestConfig): Promise<AxiosResponse<T>> {
    // create axios request config
    const axiosRequest: AxiosRequestConfig = {
      baseURL: config.baseUrl,
      method: config.method || 'get',
      url: urlJoin('/api', config.path, { leadingSlash: true, trailingSlash: false }),
      data: config.body,
      params: config.query
    }

    if (config.auth !== false) {
      const userInfo = await AuthService.getUserInfo()
      if (!userInfo) {
        throw HttpClientError.getError('AUTHENTICATION_ERROR')
      }

      // add user token to authorization header
      axiosRequest.headers = {
        'Authorization': `Bearer ${userInfo.token}`
      }
    }

    try {
      return await axios(axiosRequest) as AxiosResponse<T>
    } catch (error) {
      if (error.response) {
        throw HttpClientError.getErrorFromResponse(error.response.data.code, error.response.data.message)
      }

      throw HttpClientError.getError('NETWORK_ERROR')
    }
  }
}
