








import Vue from 'vue'
import { PromiseUtils } from '@/core/utils/promise-utils'

export default Vue.extend({
  name: 'Debug',
  data () {
    return {
      inputValue: undefined
    }
  },
  methods: {
    async onAction () {
      const { result, dialog } = await this.$buefy.dialog.confirm({
        title: 'Title',
        message: 'Message',
        closeOnConfirm: false
      })

      const confirmButton = dialog.$refs.confirmButton as HTMLElement
      confirmButton.classList.add('is-loading')

      await PromiseUtils.delay(5000)

      confirmButton.classList.remove('is-loading')

      dialog.close()
    }
  }
})
