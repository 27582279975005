
























































import Vue from 'vue'
import { IStrategyEntity } from '@/core/http/remote-interfaces/persistence-remote'
import SimpleModal from '@/ui/components/modals/SimpleModal.vue'
import { IServerItem } from '@/store/servers/types'
import store from '@/store'
import { TradingMonitoringService } from '@/core/services/trading/trading-monitoring-service'
import { IStrategyState } from '@/core/http/remote-interfaces/trading-remote'
import StrategiesTable, { IStrategyRow } from '@/ui/components/strategies/StrategiesTable.vue'
import { PromiseUtils } from '@/core/utils/promise-utils'
import { TradingStrategiesServices } from '@/core/services/trading/trading-strategies-services'
import CreateStrategyModal from '@/ui/components/strategies/CreateStrategyModal.vue'
import { IStrategyData } from '@/interfaces/strategy'

export default Vue.extend({
  name: 'ServerStrategies',
  components: {
    CreateStrategyModal,
    StrategiesTable,
    SimpleModal
  },
  props: {
    serverID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      strategies: [] as Array<IStrategyData>,
      checkedStrategies: [] as Array<IStrategyRow>,
      createStrategyModal: {
        active: false,
        loading: false,
        error: ''
      }
    }
  },
  computed: {
    serverItem (): IServerItem | undefined {
      return store.state.servers.list.find(s => s.server._id === this.serverID)
    }
  },
  async mounted () {
    await this.fetchStrategies()
  },
  methods: {
    async fetchStrategies () {
      if (!this.serverItem) {
        return
      }
      const server = this.serverItem
      try {
        const strategiesEntities = await TradingMonitoringService.getEntities(this.serverItem.server.url)
        const strategiesStates = await TradingMonitoringService.getStates(this.serverItem.server.url)

        // TODO: add instances ?
        this.strategies = strategiesEntities
          .map(entity => ({
            entity,
            state: strategiesStates.find(s => s.ID === entity._id) as IStrategyState,
            product: server.products[entity.productSymbol]
          }) as IStrategyData)
      } catch (error) {
        console.log(error.message)
      }
    },
    onCreateStrategyAction () {
      this.createStrategyModal.active = true
    },
    async onCreateStrategySubmit (strategyData: IStrategyEntity) {
      if (!this.serverItem) {
        return
      }

      this.createStrategyModal.loading = true

      try {
        const strategies = await PromiseUtils
          .minimumDelayPromise(TradingStrategiesServices.createStrategyEntity(this.serverItem.server.url, strategyData), 500)

        for (const strategy of strategies) {
          this.strategies.push({
            entity: strategy,
            state: {
              ID: strategy._id,
              hasInstance: false
            },
            product: this.serverItem.products[strategy.productSymbol]
          })
        }

        this.createStrategyModal.active = false
      } catch (error) {
        this.createStrategyModal.error = error.message
      }

      this.createStrategyModal.loading = false
    },
    async onActionCreateInstance (strategyId: string, messageDuration = 3000) {
      if (!this.serverItem) {
        return
      }

      try {
        await TradingStrategiesServices.createStrategyInstance(this.serverItem.server.url, strategyId)

        // change hasInstance flag
        const strategy = this.strategies.find(s => s.entity._id === strategyId)
        if (strategy) {
          strategy.state.hasInstance = true
        }

        // show success notification
        this.$buefy.toast.open({
          duration: messageDuration,
          message: 'Strategy instance successfully created',
          type: 'is-success'
        })
      } catch (error) {
        // show error notification
        this.$buefy.toast.open({
          duration: messageDuration,
          message: error.message,
          type: 'is-danger'
        })
      }
    },
    async onActionDeleteStrategy (strategyId: string, messageDuration = 3000) {
      if (!this.serverItem) {
        return
      }

      try {
        const strategy = await TradingStrategiesServices.deleteStrategyEntity(this.serverItem.server.url, strategyId)

        // remove strategy from list
        const strategyIndex = this.strategies.findIndex(s => s.entity._id === strategy._id)
        if (strategyIndex >= 0) {
          this.strategies.splice(strategyIndex, 1)
        }

        // show success notification
        this.$buefy.toast.open({
          duration: messageDuration,
          message: 'Strategy successfully deleted',
          type: 'is-success'
        })
      } catch (error) {
        // show error notification
        this.$buefy.toast.open({
          duration: messageDuration,
          message: error.message,
          type: 'is-danger'
        })
      }
    },

    async onActionCreateInstanceMultiple () {
      if (this.checkedStrategies.length === 0) {
        return
      }

      // show confirmation dialog
      const confirmResult = await this.$buefy.dialog.confirm({
        title: 'Creating Strategy Instance',
        message: 'Are you sure you want to <b>create</b> strategy instance ?',
        confirmText: 'Create Instance',
        type: 'is-success',
        hasIcon: true,
        closeOnConfirm: false
      })

      if (!confirmResult.result) {
        confirmResult.dialog.close()
        return
      }

      const confirmButton = confirmResult.dialog.$refs.confirmButton as HTMLElement

      // set loading state
      confirmButton.classList.add('is-loading')

      for (const checkedStrategy of this.checkedStrategies) {
        await this.onActionCreateInstance(checkedStrategy.id, 200)
      }

      // clear checked strategies
      this.onActionClearSelection()

      // remove loading state
      confirmButton.classList.remove('is-loading')

      // close confirm dialog
      confirmResult.dialog.close()
    },

    async onActionDeleteStrategyMultiple () {
      if (this.checkedStrategies.length === 0) {
        return
      }

      // show confirmation dialog
      const confirmResult = await this.$buefy.dialog.confirm({
        title: 'Deleting Strategy',
        message: 'Are you sure you want to <b>delete</b> strategy instance?',
        confirmText: 'Delete Strategy',
        type: 'is-danger',
        hasIcon: true,
        closeOnConfirm: false
      })

      if (!confirmResult.result) {
        confirmResult.dialog.close()
        return
      }

      const confirmButton = confirmResult.dialog.$refs.confirmButton as HTMLElement

      // set loading state
      confirmButton.classList.add('is-loading')

      for (const checkedStrategy of this.checkedStrategies) {
        await this.onActionDeleteStrategy(checkedStrategy.id, 200)
      }

      // clear checked strategies
      this.onActionClearSelection()

      // remove loading state
      confirmButton.classList.remove('is-loading')

      // close confirm dialog
      confirmResult.dialog.close()
    },
    onActionClearSelection () {
      this.checkedStrategies = []
    },
    async onClearDatabaseAction () {
      if (!this.serverItem) {
        return
      }

      // show confirmation dialog
      const confirmResult = await this.$buefy.dialog.confirm({
        title: 'Deleting database',
        message: 'Are you sure you want to <b>delete</b> the database of strategy and monitoring ?',
        confirmText: 'Delete database',
        type: 'is-danger',
        hasIcon: true,
        closeOnConfirm: false
      })

      if (!confirmResult.result) {
        confirmResult.dialog.close()
        return
      }

      const confirmButton = confirmResult.dialog.$refs.confirmButton as HTMLElement

      // set loading state
      confirmButton.classList.add('is-loading')

      try {
        await TradingStrategiesServices.clearDatabase(this.serverItem.server.url)
        // show success notification
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Strategy successfully deleted',
          type: 'is-success'
        })
      } catch (error) {
        // show error notification
        this.$buefy.toast.open({
          duration: 3000,
          message: error.message,
          type: 'is-danger'
        })
      }

      // remove loading state
      confirmButton.classList.remove('is-loading')

      // close confirm dialog
      confirmResult.dialog.close()

      // clear page
      await this.fetchStrategies()
    }
  }
})
