import { MutationTree } from 'vuex'
import { IServerItem, IServersState, ServersMutations } from '@/store/servers/types'
import { IAccountEntity } from '@/core/http/remote-interfaces/persistence-remote'

export const mutations: MutationTree<IServersState> = {
  [ServersMutations.CLEAR_SERVER](state) {
    state.list = []
  },

  [ServersMutations.ADD_SERVER](state, server: IServerItem) {
    state.list.push(server)
  },

  [ServersMutations.ADD_SERVER_ACCOUNT](state, { serverID, account }: { serverID: string, account: IAccountEntity }) {
    const server = state.list.find(s => s.server._id === serverID)
    if (!server) {
      return
    }

    server.accounts.push(account)
  },

  [ServersMutations.REMOVE_SERVER_ACCOUNT](state, {
    serverID,
    account
  }: { serverID: string, account: IAccountEntity }) {
    const server = state.list.find(s => s.server._id === serverID)
    if (!server) {
      return
    }

    const accountIndex = server.accounts.indexOf(account)
    if (accountIndex < 0) {
      return
    }
    server.accounts.splice(accountIndex, 1)
  }
}
