

















































































































































// risk, nb de trades, pnl
// durée du current state
// pour running : sl, tp, open price, current price

import Vue from 'vue'
import { TradingMonitoringService } from '@/core/services/trading/trading-monitoring-service'
import { TradingStrategiesServices } from '@/core/services/trading/trading-strategies-services'
import { IStrategyState } from '@/core/http/remote-interfaces/trading-remote'
import {
  IServerEntity,
  IStrategyEntity
} from '@/core/http/remote-interfaces/persistence-remote'
import { IServerItem } from '@/store/servers/types'
import store from '@/store'
import { IStrategyData } from '@/interfaces/strategy'

export default Vue.extend({
  name: 'ServerMonitoring',
  components: {},
  props: {
    serverID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      server: undefined as IServerEntity | undefined,
      entities: new Map() as Map<string, IStrategyEntity>,
      states: new Map() as Map<string, IStrategyState>,
      statesEntities: [] as Array<IStrategyData>,
      errorMessage: '',
      interval: -1,
      needsEntitiesUpdate: false,
      updating: false,
      openingSound: null as any,
      closingSound: null as any
    }
  },
  computed: {
    serverItem (): IServerItem | undefined {
      return store.state.servers.list.find(
        (s) => s.server._id === this.serverID
      )
    }
  },
  async mounted () {
    await this.getEntities()
    this.openingSound = new Audio('sounds/opening.mp3')
    this.closingSound = new Audio('sounds/closing.mp3')
    await this.update()
    this.interval = window.setInterval(() => {
      this.update()
    }, 5000)
  },
  async beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    async onDeleteInstances () {
      if (!this.serverItem) {
        return
      }

      await TradingStrategiesServices.deleteStrategiesInstance(
        this.serverItem.server.url
      )
      await this.update()
    },
    async onDeleteInstance (id: any) {
      if (!this.serverItem || !id) {
        return
      }

      await TradingStrategiesServices.deleteStrategyInstance(
        this.serverItem.server.url,
        id
      )
      await this.update()
    },
    async onToggleLooMode (id: any, value: boolean) {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.setLoopMode(
        this.serverItem.server.url,
        id,
        value
      )
      await this.update()
    },
    async onToggleLooModeAll (value: boolean) {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.setLoopModeAll(
        this.serverItem.server.url,
        value
      )
      await this.update()
    },
    async onStart (id: any) {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.start(this.serverItem.server.url, id)
      await this.update()
    },
    async onStop (id: any) {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.stop(this.serverItem.server.url, id)
      await this.update()
    },
    async onStartAll () {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.startAll(this.serverItem.server.url)
      await this.update()
    },
    async onStopAll () {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.stopAll(this.serverItem.server.url)
      await this.update()
    },
    async onStopAllSmoothly () {
      if (!this.serverItem) {
        return
      }

      await TradingMonitoringService.stopAllSmoothly(
        this.serverItem.server.url
      )
      await this.update()
    },
    async getEntities () {
      if (!this.serverItem) {
        return
      }

      const entities = await TradingMonitoringService.getEntities(
        this.serverItem.server.url
      )
      this.entities.clear()
      for (let i = 0; i < entities.length; i++) {
        this.entities.set(entities[i]._id, entities[i])
      }
    },
    async setStates (states: Array<IStrategyState>) {
      this.states.clear()
      for (let i = 0; i < states.length; i++) {
        this.states.set(states[i].ID, states[i])
      }
    },
    async update () {
      if (!this.serverItem) {
        return
      }

      if (this.updating) {
        return
      }
      this.updating = true
      this.errorMessage = ''

      try {
        if (this.needsEntitiesUpdate) {
          await this.getEntities()
        }

        const states = await TradingMonitoringService.getStates(
          this.serverItem.server.url
        )
        const items: Array<IStrategyData> = []
        for (let i = 0; i < states.length; i++) {
          let updateEntity = !this.entities.has(states[i].ID)
          const previousState = this.states.get(states[i].ID)
          if (previousState) {
            updateEntity =
              updateEntity ||
              previousState.loopID !== states[i].loopID ||
              previousState.stateIndex !== states[i].stateIndex

            // If state has changed for a strategy
            if (previousState.stateIndex !== states[i].stateIndex) {
              if (states[i].stateIndex === 1) {
                // Running
                this.openingSound.play()
              }
              if (states[i].stateIndex === 2) {
                // Closing
                this.closingSound.play()
              }
            }
          }
          if (updateEntity) {
            this.entities.set(
              states[i].ID,
              await TradingMonitoringService.getEntity(
                this.serverItem.server.url,
                states[i].ID
              )
            )
          }

          const entity = this.entities.get(states[i].ID)
          if (entity) {
            items.push({
              state: states[i],
              entity,
              product: this.serverItem.products[entity.productSymbol]
            })
          } else {
            this.needsEntitiesUpdate = true
          }
        }

        items.sort((a, b) => {
          return a.entity.productSymbol > b.entity.productSymbol ? 1 : -1
        })

        this.statesEntities = items
        this.setStates(states)
      } catch (error) {
        this.errorMessage = error.message
      }

      this.updating = false
    }
  },
  filters: {
    formatPNL: (value: number): string => {
      return `${Math.sign(value) < 0 ? '-' : ''}$${Math.abs(Math.trunc(value / 100) / 100)}`
    }
  }
})
