













import Vue from 'vue'
import store from '@/store'
import { IhmConfigService } from '@/core/services/ihm/ihm-config-service'
import { PromiseUtils } from '@/core/utils/promise-utils'
import { AppRoutes } from '@/core/constants'
import { HttpClientError } from '@/core/http/base/http-client-error'
import { AuthMutations } from '@/store/auth/types'
import { IhmServersService } from '@/core/services/ihm/ihm-servers-service'
import { IServerItem, ServersMutations } from '@/store/servers/types'
import { TradingStrategiesServices } from '@/core/services/trading/trading-strategies-services'
import { TradingAccountsService } from '@/core/services/trading/trading-accounts-service'

export default Vue.extend({
  name: 'SplashScreen',
  components: {},
  data () {
    return {
      bootstrapError: ''
    }
  },

  async mounted () {
    // check if auth.initialized flag is already set else fetch from server
    if (store.state.auth.initialized === undefined) {
      try {
        const configBootstrap = await PromiseUtils.minimumDelayPromise(IhmConfigService.configBootstrap(), 500)
        store.commit(AuthMutations.SET_INITIALIZED, configBootstrap.auth.isInitialized)
      } catch (error) {
        if (error instanceof HttpClientError) {
          this.bootstrapError = 'Error while connecting to server'
        }

        return
      }
    }

    // redirect to first user registration page if auth.initialized flag is false
    if (!store.state.auth.initialized) {
      await this.$router.replace(AppRoutes.authRegister)
      return
    }

    // redirect to login page if auth.authenticated flag is false
    if (!store.state.auth.authenticated) {
      await this.$router.replace(AppRoutes.authLogin)
      return
    }

    store.commit(ServersMutations.CLEAR_SERVER)

    // fetch servers list
    const servers = await IhmServersService.fetchServers()

    if (servers.length === 0) {
      await this.$router.replace(AppRoutes.configAddServer)
      return
    }

    // fetch servers info
    for (const server of servers) {
      // fetch strategies definitions
      const { definitions, products } = await TradingStrategiesServices.strategiesDefinitions(server.url)

      // fetch accounts
      const accounts = await TradingAccountsService.fetchAllAccounts(server.url)

      // add server to map
      store.commit(ServersMutations.ADD_SERVER, {
        server,
        definitions,
        products,
        accounts
      } as IServerItem)
    }

    await this.$router.replace(AppRoutes.dashboard)
  }
})
