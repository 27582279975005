










































import Vue from 'vue'
import { AuthService } from '@/core/services/ihm/ihm-auth-service'
import { PromiseUtils } from '@/core/utils/promise-utils'
import store from '@/store'
import { AppRoutes } from '@/core/constants'
import { AuthActions, AuthMutations } from '@/store/auth/types'

export default Vue.extend({
  name: 'AuthInitialize',
  components: {},
  data () {
    return {
      authForm: {
        username: '',
        password: ''
      },
      errorMessage: '',
      isLoading: false
    }
  },
  computed: {
    isDisabled (): boolean {
      return this.authForm.username.length === 0 && this.authForm.password.length === 0
    }
  },
  async created () {
    // redirect to login page
    if (store.state.auth.initialized) {
      await this.$router.replace(AppRoutes.splashScreen)
    }
  },
  methods: {
    async onCreate () {
      if (this.isDisabled) {
        return
      }

      this.errorMessage = ''
      this.isLoading = true

      try {
        // initialized user
        await PromiseUtils.minimumDelayPromise(AuthService.initializeUser(this.authForm), 250)
        // update store
        store.commit(AuthMutations.SET_INITIALIZED, true)
        // login user
        const userInfo = await PromiseUtils.minimumDelayPromise(AuthService.authenticateUser(this.authForm), 250)
        // update store
        await store.dispatch(AuthActions.LOGIN_USER, userInfo)
        // redirect to splashscreen
        await this.$router.replace(AppRoutes.splashScreen)
      } catch (error) {
        this.errorMessage = error.message
      }

      this.isLoading = false
    }
  }
})
