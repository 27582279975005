












import Vue from 'vue'

export default Vue.extend({
  name: 'SimpleMetricCard',
  props: {
    iconClass: {
      type: String,
      default: 'fas fa-eye'
    },
    color: {
      type: String,
      default: '#56adf7'
    },
    value: {
      type: [Number, String],
      default: '0'
    }
  },
  computed: {
    iconStyle (): Partial<CSSStyleDeclaration> {
      return {
        backgroundColor: this.color,
        fontSize: this.iconClass.startsWith('la') ? '1.5rem' : 'inherit'
      }
    }
  }
})
