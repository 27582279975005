



















































import Vue from 'vue'
import store from '@/store'
import { IServerItem } from '@/store/servers/types'

export default Vue.extend({
  name: 'AppSidebar',
  props: {
    active: {
      type: Boolean
    }
  },
  data () {
    return {}
  },
  computed: {
    servers (): Array<IServerItem> {
      return store.state.servers.list
    }
  }
})
