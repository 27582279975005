import localforage from 'localforage'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import store from '@/store'
import { IhmHttpClient } from '@/core/http/ihm-http-client'
import { IAuthUserIhmRequest, IAuthUserIhmResponse } from '@/core/http/interfaces/ihm-requests'
import { IUserEntity } from '@/core/http/remote-interfaces/persistence-remote'
import { AuthActions } from '@/store/auth/types'

export interface AuthTokenInfo {
  aud: string
  exp: number
  iat: number
  iss: string
  role: string
  sub: string
  type: string
  uid: string
}

export interface AuthUserInfo {
  user: IUserEntity,
  token: string,
  tokenInfo: AuthTokenInfo
}


export class AuthService {
  public static async initializeUser(request: IAuthUserIhmRequest): Promise<IAuthUserIhmResponse> {
    const response = await IhmHttpClient.authInitialize(request)

    return response.data
  }

  public static async authenticateUser(request: IAuthUserIhmRequest): Promise<IAuthUserIhmResponse> {
    const response = await IhmHttpClient.authLogin(request)

    return response.data
  }

  public static async getUserInfo(): Promise<AuthUserInfo | undefined> {
    if (!store.state.auth.authenticated || !store.state.auth.user || !store.state.auth.token || !store.state.auth.tokenInfo) {
      return
    }

    const user = store.state.auth.user
    const token = store.state.auth.token
    const tokenInfo = store.state.auth.tokenInfo

    // logout user if token is expired
    if (moment.unix(tokenInfo.exp).isBefore()) {
      await store.dispatch(AuthActions.LOGOUT_USER)
      return
    }

    return {
      user,
      token,
      tokenInfo
    }
  }

  public static async isAuthenticated(): Promise<boolean> {
    const userInfo = await this.getUserInfo()

    return !!userInfo
  }

  private static parseUserToken(userInfo: IAuthUserIhmResponse): AuthTokenInfo {
    return jwt.decode(userInfo.token) as AuthTokenInfo
  }
}
