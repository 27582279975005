import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { IServersState } from '@/store/servers/types'
import { getters } from '@/store/servers/getters'
import { mutations } from '@/store/servers/mutations'
import { actions } from '@/store/servers/actions'

const state: IServersState = {
  list: []
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
} as Module<IServersState, IRootState>
