import { IUserEntity } from '@/core/http/remote-interfaces/persistence-remote'
import { AuthTokenInfo } from '@/core/services/ihm/ihm-auth-service'

export interface IAuthState {
  initialized: boolean | undefined
  authenticated: boolean | undefined
  user?: IUserEntity
  token?: string
  tokenInfo?: AuthTokenInfo
}

export enum AuthMutations {
  SET_INITIALIZED = 'SET_INITIALIZED',
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_USER = 'SET_USER',
  SET_TOKEN = 'SET_TOKEN',
  SET_TOKEN_INFO = 'SET_TOKEN_INFO'
}

export enum AuthActions {
  LOGIN_USER = 'LOGIN_USER',
  LOGOUT_USER = 'LOGOUT_USER',
}
