
import { IExchangeKlineState } from '@/core/http/interfaces/trading-requests'
import { IKline, KlineInterval } from '@/core/http/remote-interfaces/exchanges-remote'
import { TradingHttpClient } from '@/core/http/trading-http-client'

export class TradingKlinesService {

  public static async cancelSync(serverUrl: string): Promise<void> {
    const response = await TradingHttpClient.klinesCancelSync(serverUrl)
  }

  public static async syncAll(serverUrl: string): Promise<void> {
    const response = await TradingHttpClient.klinesSyncAll(serverUrl)
  }

  public static async syncSymbol(serverUrl: string, symbol: string): Promise<void> {
    const response = await TradingHttpClient.klinesSyncSymbol(serverUrl, symbol)
  }

  public static async getState(serverUrl: string): Promise<IExchangeKlineState> {
    const response = await TradingHttpClient.klinesGetState(serverUrl)
    return response.data
  }

  public static async fetchKlines(serverUrl: string, symbol: string, timestampFrom?: number, timestampTo?: number, interval: number = KlineInterval['1m']): Promise<Array<IKline>> {
    const response = await TradingHttpClient.fetchKlines(
      serverUrl,
      symbol,
      timestampFrom,
      timestampTo,
      interval
    )
    return response.data
  }

}
