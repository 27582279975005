import { IhmHttpClient } from "@/core/http/ihm-http-client"
import { HealthcheckResult } from "@/core/http/remote-interfaces/healthcheck-remote"

export class IhmHealthService {

  public static async getHealth(): Promise<HealthcheckResult> {
    const response = await IhmHttpClient.getHealth()
    return response.data
  }

}
