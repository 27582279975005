































import Vue from "vue";
import { TradingMonitoringService } from "@/core/services/trading/trading-monitoring-service";
import { IExchangeWebsocketInfo } from "@/core/http/remote-interfaces/exchanges-remote";
import { IServerItem } from "@/store/servers/types";
import store from "@/store";

export default Vue.extend({
  name: "ServerExchangeWebsockets",
  components: {},
  props: {
    serverID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exchangeWebsocketsInfo: [] as Array<IExchangeWebsocketInfo>,
      errorMessage: "",
      interval: -1,
    };
  },
  computed: {
    serverItem(): IServerItem | undefined {
      return store.state.servers.list.find(
        (s) => s.server._id === this.serverID
      );
    },
  },
  async mounted() {
    await this.update();
    this.interval = window.setInterval(() => {
      this.update();
    }, 5000);
  },
  async beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async update() {
      if (!this.serverItem) {
        return;
      }

      try {
        this.errorMessage = "";
        this.exchangeWebsocketsInfo = await TradingMonitoringService.getExchangeWebsockets(
          this.serverItem.server.url
        );
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
});
