export enum KlineInterval {
  "1m" = 60,
  "5m" = 60 * 5,
  "15m" = 60 * 15,
  "30m" = 60 * 30,
  "1h" = 60 * 60,
  "4h" = 60 * 60 * 4,
  "1d" = 60 * 60 * 24,
  UNKNOWN = 0
}

export interface IKline {
  timestamp: number
  open: number
  high: number
  low: number
  close: number
  volume: number
}

export interface IKlines {
  symbol: string
  interval: KlineInterval
  items: Array<IKline>
}
