import { HttpClient } from '@/core/http/base/http-client'
import {
  ICreateStrategyTradingRequest,
  IExchangeKlineState,
  IServerInfoTradingResponse,
  IServerInitializeTradingRequest,
  IServerInitializeTradingResponse,
  IStrategiesDefinitionsTradingResponse
} from '@/core/http/interfaces/trading-requests'
import { AxiosResponse } from 'axios'
import { IAccountEntity, IMonitorStateEntity, IStrategyEntity } from '@/core/http/remote-interfaces/persistence-remote'
import { IStrategyState } from '@/core/http/remote-interfaces/trading-remote'
import { IExchangeWebsocketInfo, IKline, KlineInterval } from './remote-interfaces/exchanges-remote'

export class TradingHttpClient extends HttpClient {
  public static async serverInfo(serverUrl: string): Promise<AxiosResponse<IServerInfoTradingResponse>> {
    return this.doRequest<IServerInfoTradingResponse>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/server/info'
    })
  }

  public static async serverInitialize(request: IServerInitializeTradingRequest): Promise<AxiosResponse<IServerInitializeTradingResponse>> {
    return this.doRequest({
      method: 'post',
      baseUrl: request.url,
      path: '/server/initialize',
      body: request
    })
  }

  public static async accounts(serverUrl: string): Promise<AxiosResponse<Array<IAccountEntity>>> {
    return this.doRequest<Array<IAccountEntity>>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/accounts'
    })
  }

  public static async createAccount(serverUrl: string, request: Pick<IAccountEntity, 'name' | 'apiID' | 'apiKey'>): Promise<AxiosResponse<IAccountEntity>> {
    return this.doRequest<IAccountEntity>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/accounts',
      body: request
    })
  }

  public static async createStrategyEntity(serverUrl: string, request: ICreateStrategyTradingRequest): Promise<AxiosResponse<Array<IStrategyEntity>>> {
    return this.doRequest<Array<IStrategyEntity>>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/strategies/entities',
      body: request
    })
  }

  public static async getStrategiesEntities(serverUrl: string): Promise<AxiosResponse<Array<IStrategyEntity>>> {
    return this.doRequest<Array<IStrategyEntity>>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/entities'
    })
  }

  public static async getStrategiesEntity(serverUrl: string, id: string): Promise<AxiosResponse<IStrategyEntity>> {
    return this.doRequest<IStrategyEntity>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/entities/' + id
    })
  }

  public static async deleteStrategyEntity(serverUrl: string, id: string): Promise<AxiosResponse<IStrategyEntity>> {
    return this.doRequest<IStrategyEntity>({
      baseUrl: serverUrl,
      method: 'delete',
      path: `/strategies/entities/${id}`
    })
  }

  public static async getStrategyState(serverUrl: string, id: string): Promise<AxiosResponse<IStrategyState>> {
    return this.doRequest<IStrategyState>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/strategies/states/${id}`
    })
  }

  public static async getStrategiesStates(serverUrl: string): Promise<AxiosResponse<Array<IStrategyState>>> {
    return this.doRequest<Array<IStrategyState>>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/states'
    })
  }

  public static async setStrategyLoopMode(serverUrl: string, id: string, loopMode: boolean): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/strategies/loop-mode/' + id,
      body: { loopMode: loopMode }
    })
  }

  public static async setStrategiesLoopMode(serverUrl: string, loopMode: boolean): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/strategies/loop-mode',
      body: { loopMode: loopMode }
    })
  }

  public static async createStrategyInstance(serverUrl: string, id: string): Promise<AxiosResponse<object>> {
    return this.doRequest<object>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/strategies/instances/create/${id}`
    })
  }

  public static async deleteStrategyInstance(serverUrl: string, id: string): Promise<AxiosResponse<object>> {
    return this.doRequest<object>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/strategies/instances/delete/${id}`
    })
  }

  public static async deleteStrategiesInstance(serverUrl: string): Promise<AxiosResponse<object>> {
    return this.doRequest<object>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/strategies/instances/delete`
    })
  }

  public static async strategyStop(serverUrl: string, id: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/instances/stop/' + id
    })
  }

  public static async strategyStart(serverUrl: string, id: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/instances/start/' + id
    })
  }

  public static async strategiesStopSmoothly(serverUrl: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/instances/stop-smoothly'
    })
  }

  public static async strategiesStop(serverUrl: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/instances/stop'
    })
  }

  public static async strategiesStart(serverUrl: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/instances/start'
    })
  }

  public static async getExchangeWebsockets(serverUrl: string): Promise<AxiosResponse<Array<IExchangeWebsocketInfo>>> {
    return this.doRequest<Array<IExchangeWebsocketInfo>>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/exchange-websockets-info'
    })
  }

  public static async deleteAccount(serverUrl: string, accountID: string): Promise<AxiosResponse<IAccountEntity>> {
    return this.doRequest<IAccountEntity>({
      baseUrl: serverUrl,
      method: 'delete',
      path: `/accounts/${accountID}`
    })
  }

  public static async verifyAccount(serverUrl: string, accountID: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/accounts/verify/${accountID}`
    })
  }

  public static async strategiesDefinitions(serverUrl: string): Promise<AxiosResponse<IStrategiesDefinitionsTradingResponse>> {
    return this.doRequest<IStrategiesDefinitionsTradingResponse>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/strategies/definitions'
    })
  }

  public static async clearDatabase(serverUrl: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'delete',
      path: '/strategies/database/clear'
    })
  }

  public static async klinesCancelSync(serverUrl: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/klines/sync/cancel'
    })
  }

  public static async klinesSyncAll(serverUrl: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/klines/sync/all'
    })
  }

  public static async klinesSyncSymbol(serverUrl: string, symbol: string): Promise<AxiosResponse<void>> {
    return this.doRequest<void>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/klines/sync/symbol/' + symbol
    })
  }

  public static async klinesGetState(serverUrl: string): Promise<AxiosResponse<IExchangeKlineState>> {
    return this.doRequest<IExchangeKlineState>({
      baseUrl: serverUrl,
      method: 'get',
      path: '/klines/sync/state'
    })
  }

  public static async fetchKlines(serverUrl: string, symbol: string, timestampFrom?: number, timestampTo?: number, interval: KlineInterval = KlineInterval['1m']): Promise<AxiosResponse<Array<IKline>>> {
    return this.doRequest<Array<IKline>>({
      baseUrl: serverUrl,
      method: 'post',
      path: '/klines/history/' + symbol,
      body: {
        from: timestampFrom,
        to: timestampTo,
        interval: interval
      }
    })
  }

  public static async getStrategyMonitorStates(serverUrl: string, strategyId: string): Promise<AxiosResponse<Array<IMonitorStateEntity>>> {
    return this.doRequest<Array<IMonitorStateEntity>>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/monitor-states/${strategyId}`
    })
  }

  public static async getStrategyMonitorStatesAndLoop(serverUrl: string, strategyId: string, loopId: string): Promise<AxiosResponse<Array<IMonitorStateEntity>>> {
    return this.doRequest<Array<IMonitorStateEntity>>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/monitor-states/${strategyId}/loop/${loopId}`
    })
  }

  public static async getStrategyMonitorStatesFromLoop(serverUrl: string, strategyId: string, loopId: string, inclusive?: boolean): Promise<AxiosResponse<Array<IMonitorStateEntity>>> {
    return this.doRequest<Array<IMonitorStateEntity>>({
      baseUrl: serverUrl,
      method: 'get',
      path: `/monitor-states/${strategyId}/from/${loopId}`,
      query: inclusive ? { inclusive: true } : undefined
    })
  }
}
