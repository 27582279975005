const HttpClientErrors = {
  AUTHENTICATION_ERROR: {
    message: 'Request authentication error'
  },
  NETWORK_ERROR: {
    message: 'Network request error'
  }
} as const

export class HttpClientError extends Error {
  public readonly code: string
  public readonly causeError?: Error

  protected constructor (code: string, message: string, causeError?: Error) {
    super(message)

    this.name = this.constructor.name
    this.code = code
    this.causeError = causeError

    function nonEnumerableProperty (value: string | undefined) {
      // The field `enumerable` is `false` by default.
      return {
        value: value,
        writable: true,
        configurable: true
      }
    }

    // set stack to cause error stack if provided
    if (causeError) {
      this.stack = causeError.stack
    } else {
      if ('captureStackTrace' in Error) {
        // V8 specific method.
        Error.captureStackTrace(this, this.constructor)
      } else {
        // Generic way to set the error stack trace.
        Object.defineProperty(this, 'stack', nonEnumerableProperty(this.stack))
      }
    }

    // force prototype for compatibility
    Object.setPrototypeOf(this, this.constructor.prototype)
  }

  public static getError (code: keyof typeof HttpClientErrors, causeError?: Error): HttpClientError {
    return new HttpClientError(code, HttpClientErrors[code].message, causeError)
  }

  public static getErrorFromResponse (code: string, message: string): HttpClientError {
    return new HttpClientError(code, message)
  }
}
