

























































import Vue, { PropType } from 'vue'

interface IFilters {
  products: Array<string>,
  strategies: Array<string>,
  instance: boolean | null
}

export default Vue.extend({
  name: 'StrategiesTableFilters',
  props: {
    filters: {
      type: Object as PropType<IFilters>,
      required: true
    },
    products: {
      type: Array as PropType<Array<string>>,
      default: []
    },
    strategies: {
      type: Array as PropType<Array<string>>,
      default: []
    }
  }
})
