import { MutationTree } from 'vuex'
import { AuthMutations, IAuthState } from '@/store/auth/types'
import { IUserEntity } from '@/core/http/remote-interfaces/persistence-remote'

export const mutations: MutationTree<IAuthState> = {
  [AuthMutations.SET_INITIALIZED](state, initialized?: boolean) {
    state.initialized = initialized
  },
  [AuthMutations.SET_AUTHENTICATED](state, authenticated?: boolean) {
    state.authenticated = authenticated
  },
  [AuthMutations.SET_USER](state, user?: IUserEntity) {
    state.user = user
  },
  [AuthMutations.SET_TOKEN](state, token?: string) {
    state.token = token
  },
  [AuthMutations.SET_TOKEN_INFO](state, tokenInfo: any) {
    state.tokenInfo = tokenInfo
  }
}
