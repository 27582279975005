import { IOrder, OrderSide } from '../../../../exchanges/src/api/interfaces/payloads/order'

export enum MonitorStateType {
  OPENING = 'MonitorStateOpening',
  RUNNING = 'MonitorStateRunning',
  CLOSING = 'MonitorStateClosing'
}

export interface IMonitorStateEntity {
  _id?: any;
  strategyID: string;
  stateType: MonitorStateType;
  loopID: number;
  startTimestamp: number;
  endTimestamp: number;
  label: string;
  jsonString: string;
}

//
// MonitorStateOpening
//
export interface IMonitorStateOpeningEntity extends IMonitorStateEntity {
  side: number;
  orderSide: OrderSide;
  openBalance: number;
  openPrice: number;
  cumulatedPnl: number;
  cumulatedClosedPnl: number;
  cumulatedFundingFee: number;
  cumulatedTransactFee: number;
  attempts: number;
}

//
// MonitorStateRunning
//
export interface IMonitorStateRunningEntity extends IMonitorStateEntity {
  leverage: number;
  maxLossAllowed: number;
  stopLossPrice: number;
  takeProfitPrice: number;
  placedContracts: number;
  entryPrice: number;
  orderID: string;
  order: IOrder | null;
  filledContracts: number;
  soldContracts: number;
  averageEntryPrice: number;
  unrealisedPnl: number;
  minUnrealisedPnl: number;
  maxUnrealisedPnl: number;
  currentPrice: number;
  highPrice: number;
  lowPrice: number;
  lowAsk: number;
  highAsk: number;
  lowBid: number;
  highBid: number;
  risk: number;
}

//
// MonitorStateClosing
//
export interface IMonitorStateClosingEntity extends IMonitorStateEntity {
  closePrice: number;
  closeBalance: number;
  cumulatedPnl: number;
  cumulatedClosedPnl: number;
  cumulatedFundingFee: number;
  cumulatedTransactFee: number;
  closedPnl: number;
  fundingFee: number;
  transactFee: number;
  pnl: number;
  pnlPercentage: number;
  ratioRiskReward: number;
}
