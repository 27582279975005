export const AppRoutes = {
  splashScreen: '/',

  authRegister: '/auth/register',
  authLogin: '/auth/login',

  configAddServer: '/config/add-server',

  dashboard: '/dashboard',

  serverStrategies: '/server/:serverID/strategies',
  serverStrategyDetails: '/server/:serverId/strategies/:strategyId',
  serverAccounts: '/server/:serverID/accounts',
  serverMonitoring: '/server/:serverID/monitoring',
  serverExchangeWebsockets: '/server/:serverID/exchange-websockets',
  serverKlines: '/server/:serverID/klines',

  users: '/users',
  health: '/health'

}
