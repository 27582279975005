










import Vue from 'vue'

export default Vue.extend({
  name: 'SimpleMetricField',
  props: {
    icon: {
      type: String,
      default: 'eye'
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#56adf7'
    },
    size: {
      type: String,
      default: 'is-medium'
    },
    isRound: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {
    iconStyle (): Partial<CSSStyleDeclaration> {
      return {
        color: '#ffffff',
        backgroundColor: this.color,
        borderRadius: this.isRound ? '4px' : '0'
      }
    }
  }
})
