
















import Vue, { PropType } from 'vue'
import { IStrategyConfigSchema } from '@/core/http/interfaces/trading-requests'
import NumberInput from '@/ui/components/common/NumberInput.vue'

export default Vue.extend({
  name: 'schema-number',
  components: { NumberInput },
  props: {
    name: {
      type: String
    },
    schema: {
      type: Object as PropType<IStrategyConfigSchema>
    },
    value: {
      type: [Number, String],
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showType: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isInvalid: false
    }
  },
  computed: {
    optionMin (): number | undefined {
      if (!this.schema.rules) {
        return
      }

      const rule = this.schema.rules.find(rule => rule.name === 'min')
      return rule && rule.args ? rule.args.limit : undefined
    },
    optionMax (): number | undefined {
      if (!this.schema.rules) {
        return
      }

      const rule = this.schema.rules.find(rule => rule.name === 'max')
      return rule && rule.args ? rule.args.limit : undefined
    },
    optionStep (): number {
      if (!this.schema.metas) {
        return 1
      }

      const metaStep = this.schema.metas.find(meta => !!meta.step)
      return metaStep && metaStep.step ? metaStep.step : 1
    },
    example (): number | undefined {
      return this.schema.examples ? this.schema.examples[0] : undefined
    }
  },
  methods: {
    onInput (value: string) {
      this.$emit('input', value)
    }
  }
})
