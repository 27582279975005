


































import Vue, { PropType } from 'vue'
import { IServerItem } from '@/store/servers/types'
import { IStrategyData } from '@/interfaces/strategy'
import { IMonitorStateLoopData } from '@/interfaces/monitor-state'
import { IStrategyState } from '@/core/http/remote-interfaces/trading-remote'
import { IMonitorStateEntity } from '@/core/http/remote-interfaces/persistence-remote'
import OpeningMonitorState from '@/ui/components/monitor-states/OpeningMonitorState.vue'
import ClosingMonitorState from '@/ui/components/monitor-states/ClosingMonitorState.vue'
import RunningMonitorState from '@/ui/components/monitor-states/RunningMonitorState.vue'
import MonitorStateDetailsModal from '@/ui/components/monitor-states/MonitorStateDetailsModal.vue'
import MonitorStateChartModal from '@/ui/components/monitor-states/MonitorStateChartModal.vue'

export default Vue.extend({
  name: 'StrategyStates',
  components: {
    MonitorStateChartModal,
    MonitorStateDetailsModal,
    RunningMonitorState,
    ClosingMonitorState,
    OpeningMonitorState
  },
  props: {
    server: {
      type: Object as PropType<IServerItem>,
      required: true
    },
    strategy: {
      type: Object as PropType<IStrategyData>,
      required: true
    },
    states: {
      type: Array as PropType<Array<IMonitorStateLoopData>>,
      required: true
    },
    currentState: {
      type: Object as PropType<IStrategyState>,
      required: false
    }
  },
  data () {
    return {
      activeStateDetails: undefined as IMonitorStateEntity | undefined,
      activeStateChart: undefined as IMonitorStateLoopData | undefined
    }
  },
  methods: {
    onDetailsClick (state: IMonitorStateEntity) {
      this.activeStateDetails = state
    },
    onDetailsClose () {
      this.activeStateDetails = undefined
    },
    onChartClick (state: IMonitorStateLoopData) {
      this.activeStateChart = state
    },
    onChartClose () {
      this.activeStateChart = undefined
    }
  }
})
